appbuilder.app.Navigator.screenSetups = appbuilder.app.Navigator.screenSetups || {};
appbuilder.app.Navigator.screenSetups.map = function(screen, html) {

	var items = screen.getElement('.items'),
	mapHolder = appbuilder.app.makeElement('map-holder').inject(items),
	mapsLoading = new appbuilder.app.Loader(null, 'Loading Google Maps'),
	hiddenItems = new Element('div', {
		'class':'hidden'
	}).inject(screen);

	document.id(mapsLoading).inject(items);
	mapsLoading.showLoading();
	var data = JSON.decode(screen.getElement('script').get('html'), true);
	//The elements are put into a hidden element in the screen so that they get
	//picked up and made editable, they are not in the items element as it gets
	//cleared by 'new google.maps.Map' before they are made editable
	data.each(function(point) {
		var element = new Element('div').inject(hiddenItems);
		element.set('html', point.html);
		element.set('html', element.get('text'));
		point.element = element;
	});
	var phone = this.phone;

	phone.navigator.moveAdsInToScreen(screen);

	appbuilder.map.include(function(loaded) {
		if(loaded) {
			appbuilder.app.debug('maps', 'load', 'loaded');
			mapsLoading.hideLoading();
			document.id(mapsLoading).destroy();
			var mapOptions = {
				'mapTypeId' : google.maps.MapTypeId.ROADMAP,
				mapTypeControl: false,
				zoomControlOptions: {
					position: google.maps.ControlPosition.RIGHT_CENTER
				},
				streetViewControl: false
			};

			var map = new google.maps.Map(mapHolder, mapOptions),
			bounds = new google.maps.LatLngBounds(),
			p;
			data.each(function(point) {
				appbuilder.app.debug('maps', 'makePoint', point);
				p = new google.maps.LatLng(point.latitude, point.longitude);
				var icon = new google.maps.MarkerImage(appbuilder.app.mapMarker, new google.maps.Size(31, 40), new google.maps.Point(0, 0), new google.maps.Point(8, 35)),
				markerOptions = {
					clickable : true,
					title : point.title,
					position : p,
					map: map,
					icon: icon
				},
				marker = new google.maps.Marker(markerOptions),
				infoBox;
				google.maps.event.addListener(marker, 'click', (function() {
					if(!infoBox) {
						infoBox = new InfoBox({
							latlng: marker.getPosition(),
							element: point.element,
							map: map
						});
					}
					else {
						infoBox.setMap(map);
					}
				}).bind(this));


				bounds.extend(marker.position);
			});

			var mapSatelite = new Element('div',{'class':'mapControlborder'});
			new Element('div',{'class':'mapControlInterior','html':'Satellite'}).inject(mapSatelite);
			map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(mapSatelite);


			var mapRoadmap = new Element('div',{'class':'mapControlborder'});
			new Element('div',{'class':'mapControlInterior','html':'Map'}).inject(mapRoadmap);
			map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(mapRoadmap);


			mapSatelite.addEvent('click',function(){
				map.setMapTypeId(google.maps.MapTypeId.SATELLITE);
			});
			mapRoadmap.addEvent('click',function(){
				map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
			});



			//google.maps.event.addListener(map, 'tilesloaded', (function() {
			//	(function(){
			//		mapRoadmap.setStyles({
			//			'bottom': '66px'
			//		});
			//		mapSatelite.setStyles({
			//			'bottom': '34px'
			//		});
			//	}).delay(500)
			//}).bind(this));



			if(data.length == 0) {
				appbuilder.app.debug('maps', 'make', 'noPoints');
				map.setCenter(new google.maps.LatLng(0, 0));
				map.setZoom(0);
			}
			else if(data.length == 1) {
				appbuilder.app.debug('maps', 'make', 'onePoint');
				map.setCenter(p);
				map.setZoom(parseInt(screen.get('data-zoom')) || 12);
			}
			else {
				map.fitBounds(bounds);
			}
		}
		else {
			appbuilder.app.debug('maps', 'load', 'failed');
			mapsLoading.setMessage('Failed to load Google Maps');
			mapsLoading.hideLoadingAni();
		}
	});
};
