appbuilder = window.appbuilder || {};
appbuilder.app = appbuilder.app || {};
appbuilder.app.JSAPI = new Class({
	initialize: function(phone) {
		this.phone = phone;
        appbuilder.app.api = this;
	},

	isPhoneGap: appbuilder.app.isPhoneGap,
	isMobile: appbuilder.app.isMobile,

	showApp: function(id) { //ACTION_APP
		appbuilder.app.debug('api', 'showApp', id);
		this.phone.navigator.linktypes['app'].call(this.phone.navigator, ""+id);
	},

	showTab: function(id) {//ACTION_TAB
		appbuilder.app.debug('api', 'showTab', id);
		this.phone.navigator.linktypes['tab'].call(this.phone.navigator, ""+id);
	},

	showScreen: function(id,animate) {//ACTION_SCREEN
		appbuilder.app.debug('api', 'showScreen', id);
		this.phone.navigator.linktypes['screen'].call(this.phone.navigator, ""+id,animate);
	},
    showWeb: function(url, type) {//ACTION_WEB
		appbuilder.app.debug('api', 'showWeb', url);
		this.phone.navigator.linktypes['web'].call(this.phone.navigator, url, type);
	},

	showFile: function(url) {//ACTION_FILE, ACTION_VIDEO
		appbuilder.app.debug('api', "showFile", url);
		this.phone.navigator.linktypes['file'].call(this.phone.navigator, url);
	},

	showYoutube: function(url) {//ACTION_YOUTUBE
		appbuilder.app.debug('api', 'showYoutube', url);
		this.phone.navigator.linktypes['youtube'].call(this.phone.navigator, url);
	},

	showVimeo: function(url) {//ACTION_VIMEO
		appbuilder.app.debug('api', 'showVimeo', url);
		this.phone.navigator.linktypes['vimeo'].call(this.phone.navigator, url);
	},

	showTwitter: function(username) {//ACTION_TWITTER
		appbuilder.app.debug('api', 'showTwitter', username);
		this.phone.navigator.linktypes['twitter'].call(this.phone.navigator, username);
	},

	showFacebook: function(url) {//ACTION_FACEBOOK
		appbuilder.app.debug('api', 'showFacebook', url);
		this.phone.navigator.linktypes['facebook'].call(this.phone.navigator, url);
	},

	showBBM: function() {// ACTION_BBM
		appbuilder.app.debug('api', 'showBBM');
		this.phone.navigator.linktypes['bbm'].call(this.phone.navigator);
	},

	playSound: function(url) {//ACTION_SOUND
		return appbuilder.app.sound.play(url);
	},

	stopSound: function(a) {
		appbuilder.app.sound.stop(a);
	},

	back: function() {//ACTION_BACK
		appbuilder.app.debug('api', 'back');
		this.phone.navigator.linktypes['back'].call(this.phone.navigator);
	},

    showRemoteScreen: function(href, direct, animate, back, historyChange) {//ACTION_REMOTE_JSON, ACTION_EXTENSION, ACTION_RSS

        this.phone.navigator.checkVariables(href, function (href, postVars) {
                this.phone.fetchLive(direct ? 'direct' : 'remote', href, function (screenId, fetchObj) {
                    this.phone.loader.hideLoading();
                    if (screenId && fetchObj) {
                        if (fetchObj.settings.maintype == 'app') {
                            this.phone.history.clearHistory();
                            this.phone.history.currentApp = screenId;
                            this.phone.history.currentAppUrl = href;
                            this.showApp(fetchObj.app[screenId], animate, null, screenId, true);
                        }
                        else {
                            this.showScreen(fetchObj.screen[screenId], screenId, animate, back, historyChange);
                        }
                    }
                    else if (screenId) {
                        this.phone.fetch('screen', screenId, function (obj) {
                            if (obj) {
                                this.showScreen(obj, screenId, animate, back, historyChange);
                            }
                            else {
                                appbuilder.app.alert('This screen is currently unavailable', this);
                                this.postLink();
                            }
                        }.bind(this), screenId);
                    }
                    else {
                        this.postLink();
                    }
                }.bind(this), postVars);
            }.bind(this.phone.navigator), true);
        appbuilder.app.debug('api', 'showRemoteScreen', href);
    },

    tel: function(tel) {//ACTION_PHONE
        appbuilder.app.debug('api', 'phone', tel);
        var url = 'tel:'+tel;
        this.phone.navigator.linktypes['web'].call(this.phone.navigator, url, 'confirm');
    },

    email: function(to,subject, body) {//ACTION_EMAIL
        appbuilder.app.debug('api', 'email', to);
        var url = 'mailto:'+to+'?subject='+encodeURIComponent(subject)+'&body='+encodeURIComponent(body);
        this.phone.navigator.linktypes['web'].call(this.phone.navigator, url, 'confirm');
    },

    js: function(code,t) {//ACTION_CUSTOM_JAVASCRIPT
        appbuilder.app.debug('api', 'code', code);

        if (code) {
            try {
                new Function('app', code).call(t, appbuilder.app.api);
            }
            catch (e) {
                appbuilder.app.debug('navigator', 'Error with custom js action', e);
            }
        }

    },

    iot: function (name, value) {//ACTION_IOT
        appbuilder.app.debug('api', 'iot', name, value);
        if (window.appbuilder.events) {
            if (value === 'toggle') {
                window.appbuilder.events.iot.togglePinValue(name);
            } else {
                window.appbuilder.events.iot.setPin(name, value);
            }
        }
    },

    getVariable: function(name) {
		appbuilder.app.debug('api', 'getVariable', name);
		var formField = this.phone.navigator.loadVar(name);
		if(formField) {
			return formField.value;
		}
		return null;
	},

	setVariable: function(name, value,method,type) {
		appbuilder.app.debug('api', 'setVariable', name, value);
		this.phone.navigator.saveVar(name, value,method,type);
		var item = document.getElement('[data-variable='+name+']');
		if(item) {
			item.set('value', value);
		}
	},

	addTabEvent: function(id, func) {
		var f = function(eid, tab) {
			if(id == eid) {
				appbuilder.app.debug('api', 'tabEvent', id);
				try {
					func.call(tab, this);
				}
				catch(e) {
					appbuilder.app.debug('api', 'Error with custom js on tab event', e);
				}
			}
		}.bind(this);
		this.phone.addEvent('tab', f);
		return f;
	},

	removeTabEvent: function(identifier) {
		this.phone.removeEvent('tab', identifier);
	},

	addScreenEvent: function(id, func) {
		var f = function(eid, screen) {
			if(id == eid) {
				appbuilder.app.debug('api', 'screenEvent', id);
				try {
					func.call(screen, this);
				}
				catch(e) {
					appbuilder.app.debug('api', 'Error with custom js on screen event', e);
				}
			}
		}.bind(this);
		this.phone.addEvent('screen', f);
	},

	removeScreenEvent: function(identifier) {
		this.phone.removeEvent('screen', identifier);
	},

	addVariableEvent: function(name, func) {
		var f = function(eName, value) {
			if(name == eName) {
				appbuilder.app.debug('api', 'variableEvent', name);
				try {
					func.call(window, value, this);
				}
				catch(e) {
					appbuilder.app.debug('api', 'Error with custom js on variable event', e);
				}
			}
		}.bind(this);
		this.phone.addEvent('varChange', f);
		return f;
	},

	removeVariableEvent: function(identifier) {
		this.phone.removeEvent('varChange', identifier);
	},

	runOnce: function(id, func) {
		if(typeof id === 'function') {
			func = id;
			id = this.phone.history.currentApp;
		}
		if(!appbuilder.app.JSAPI.runonce[id]) {
			appbuilder.app.JSAPI.runonce[id] = true;
			try {
				func.call(window, this);
			}
			catch(e) {
				appbuilder.app.debug('api', 'Error with custom js on variable event', e);
			}
		}
	},

    getAppId: function(id) {
        return id;
    },

    getTabId: function(id) {
        return id;
    },

    getScreenId: function(id) {
        return id;
    },

    getItemId: function(id) {
        return id;
    },

    getCurrentAppId: function() {
        return appbuilder.app.current.history.currentApp;
    },

    getCurrentTabId: function() {
        return appbuilder.app.current.history.currentTabId;
    },

    getCurrentScreenId: function() {
        return appbuilder.app.current.history.currentScreenId;
    },

    closeConfirm:function(){
        var confirms = appbuilder.app.current.element.getElements('.confirm');
        if(confirms){
            confirms.destroy();
        }
    },

    /**
     *
     * @param variable
     * @param period how often to call this url
     * @param method equal, notequal, lessthan, greaterthan, lessthanorequalto, greaterthanorequalto
     * @param value the value to compare with
     * @param url
     * @param range the range of values to allow
     * @param callback
     * @returns {eventDescriptor} use this to remove the event
     */
    addApiEvent: function(variable, period, method, url, range, value, callback) {
        return this.phone.navigator.events.addEvent({
            type: 'url',
            variable: variable,
            url: url,
            period: period,
            method: method,
            value: value,
            range: range,
            callback: callback
        });
    },

    /**
     * @param eventDescriptor
     */
    removeEvent: function(eventDescriptor) {
        this.phone.navigator.events.removeEvent(eventDescriptor);
    },

    /**
     * @param variable
     * @param value
     * @param range
     * @param method
     * @param callback
     * @returns {eventDescriptor} use this to remove the event
     */
    addIotEvent: function (variable, value, range, method, callback) {
      return this.phone.navigator.events.addEvent({
          type: 'iot',
          variable: variable,
          value: value,
          range: range,
          method: method,
          callback: callback
      });
    },

    /**
     * @param callback
     * @returns {eventDescriptor} use this to remove the event
     */
    addShakeEvent: function (callback) {
        console.log(this);
      return this.phone.navigator.events.addEvent({
          type: 'shake',
          callback: callback
      });
    },

    /**
     *
     * @param value
     * @param range
     * @param callback
     * @returns {eventDescriptor}
     */
    addCompassEvent: function (value, range, callback) {
        return this.phone.navigator.events.addEvent({
            type: 'compass',
            value: value,
            range: range,
            callback: callback
        });
    },

    /**
     *
     * @param lat
     * @param lng
     * @param range
     * @param method
     * @param callback
     * @returns {eventDescriptor}
     */
    addLocationEvent: function (lat, lng, range, method, callback) {
        return this.phone.navigator.events.addEvent({
            type: 'location',
            lat: lat,
            lng: lng,
            range: range,
            method: method,
            callback: callback
        });
    },

    setPin: function (pinName, val) {
        window.appbuilder.events.iot.setPin(pinName, val);
    },

    getPinValue: function (pinName) {
        return window.appbuilder.events.iot.getPinValue(pinName);
    },

    togglePinValue: function (pinName) {
        window.appbuilder.events.iot.togglePinValue(pinName);
    },

    ajaxRequest: function (url, httpMethod, responseType, parameters, successCb, failCb) {
        var req,
            params = {
                url: url,
                data: parameters,
                method: httpMethod == 'jsonp' ? 'get' : httpMethod,
                onSuccess: function (response) {
                    successCb(response)
                },
                onFailure: function () {
                    failCb('Sorry, your request failed :(');
                }
            };

        if (httpMethod == 'jsonp') {
            req = new Request.JSONP(params);

        } else if (responseType == 'json') {
            req = new Request.JSON(params);
        }
        else {
            req = new Request(params);
        }

        req.send();
    }

});
