appbuilder.app.Navigator.screenSetups = appbuilder.app.Navigator.screenSetups || {};
appbuilder.app.Navigator.screenSetups.list = function(screen, html) {
	if(!appbuilder.app.isIE){
	screen.getElements('select').each(function(selectEl) {
		appbuilder.app.debug('list', 'select');
		var options = [], val = selectEl.get('value') , name = selectEl.get('name');
		selectEl.getElements('option').each(function(opEl) {
			var v = opEl.get('value');
			options.push({
				'value': v, 
				'title': opEl.get('text'), 
				'selected':val == v
			});
		});
		var p = selectEl.getParent();
		selectEl.destroy();
		selectEl = appbuilder.app.makeElement('selected', {}, 'span').inject(p);

		if(typeOf(options) == 'array' && options.length > 0) {
			var done, cancel, oset = selectEl.set, oget = selectEl.get, i;
			var spinnerOptions = {};
			options.each(function(op) {
				spinnerOptions[op.value] = op.title;
			});

			selectEl.set = function(what, value) {
				if(what == 'value') {
					this.set('data-value', value.value || value);
					this.set('data-variable', name );
					this.set('text', value.title || spinnerOptions[value] || value);
					selectEl.fireEvent('change');
				}
				else {
					oset.apply(this, arguments);
				}
			};
			selectEl.get = function(what) {
				if(what == 'value') {
					return this.get('data-value');
				}
				else {
					return oget.apply(this, arguments);
				}
			};

			if(!options.some(function(option) {
				if(option.selected) {
					selectEl.set('value', option);
					return true;
				}
			})) {

				selectEl.set('value', options[0]);
			}

			selectEl.addEvent('click', function(e) {
				if(!SpinningWheel.swWrapper) {
					e.stop();
					e.preventDefault();
					appbuilder.app.decoy();
					SpinningWheel.addSlot(spinnerOptions, '', selectEl.get('value'));
					SpinningWheel.setCancelAction(cancel);
					SpinningWheel.setDoneAction(done);

					SpinningWheel.open();
				}
			});

			done = function() {
				var results = SpinningWheel.getSelectedValues();
				selectEl.set('value', results.keys[0]);
			};

			cancel = function() {};
		}
	});
	}
	screen.getElements('[data-picker-type]').each(function(pickerEl) {
		appbuilder.app.debug('list', 'picker');
		var type = pickerEl.get('data-picker-type');
		//if(appbuilder.app.isMobile) {
		var now = new Date(), done, cancel, oset = pickerEl.set, oget = pickerEl.get, i;
		if(type == 'date') {
			var months = {
				"0":"January",
				"1":"Feburary",
				"2":"March",
				"3":"April",
				"4":"May",
				"5":"June",
				"6":"July",
				"7":"August",
				"8":"September",
				"9":"October",
				"10":"November",
				"11":"December"
			};
			var days = {
				"1":"1",
				"2":"2",
				"3":"3",
				"4":"4",
				"5":"5",
				"6":"6",
				"7":"7",
				"8":"8",
				"9":"9",
				"10":"10",
				"11":"11",
				"12":"12",
				"13":"13",
				"14":"14",
				"15":"15",
				"16":"16",
				"17":"17",
				"18":"18",
				"19":"19",
				"20":"20",
				"21":"21",
				"22":"22",
				"23":"23",
				"24":"24",
				"25":"25",
				"26":"26",
				"27":"27",
				"28":"28",
				"29":"29",
				"30":"30",
				"31":"31"
			};
			var years = {};
			var thisYear = now.getFullYear();
			var yearRange = parseInt(pickerEl.get('data-year-range') || 200);
			for(i = thisYear -  yearRange;i < thisYear + yearRange;i++) {
				years[i] = '' + i;
			}

			pickerEl.set = function(what, value) {
				if(what == 'value') {
					if(typeOf(value) != 'date') {
						value = new Date(parseInt(value));
					}
					if(isNaN(value)) {
						value = new Date();
					}
					this.set('data-value', value.getTime());
					this.set('text', value.toLocaleDateString());
					pickerEl.fireEvent('change');
				}
				else {
					oset.apply(this, arguments);
				}
			};
			pickerEl.get = function(what) {
				if(what == 'value') {
					return parseInt(this.get('data-value') || new Date().getTime());
				}else if(what == 'type') {
					return type;
				}
				else {
					return oget.apply(this, arguments);
				}
			};

			pickerEl.set('value', pickerEl.get('data-value') || new Date());

			pickerEl.addEvent('click', function(e) {
				if(appbuilder.app.isIE) {
					// disable ie <= 8
					appbuild.app.alert('This feature is not supported on the browser you are using (Internet Explorer 8). Please try again using IE9, Firefox or Chrome.');
					return;
				}
				if(!SpinningWheel.swWrapper) {
					e.stop();
					e.preventDefault();

					appbuilder.app.decoy();

					var val = new Date(pickerEl.get('value'));

					SpinningWheel.addSlot(days, 'right', val.getDate());
					SpinningWheel.addSlot(months, '', val.getMonth());
					SpinningWheel.addSlot(years, '', val.getFullYear());

					SpinningWheel.setCancelAction(cancel);
					SpinningWheel.setDoneAction(done);

					SpinningWheel.open();
				}
			});

			done = function() {
				var results = SpinningWheel.getSelectedValues();
				var val = new Date(results.keys[2], results.keys[1], results.keys[0]);
				pickerEl.set('value', val.getTime());
			};

			cancel = function() {};
		}
		else if(type == 'time') {
			var hours = {
				"1":"1",
				"2":"2",
				"3":"3",
				"4":"4",
				"5":"5",
				"6":"6",
				"7":"7",
				"8":"8",
				"9":"9",
				"10":"10",
				"11":"11",
				"12":"12"
			};
			var mins = {
				"0":"00",
				"5":"05",
				"10":"10",
				"15":"15",
				"20":"20",
				"25":"25",
				"30":"30",
				"35":"35",
				"40":"40",
				"45":"45",
				"50":"50",
				"55":"55"
			};
			var ampm = {
				"am":"am", 
				"pm":"pm"
			};

			pickerEl.set = function(what, value) {
				if(what == 'value') {
					this.set('data-value', value);

					var val = pickerEl.get('value').split(':');
					if(val.length == 1) {
						var d = new Date(pickerEl.get('value'));
						if(isNaN(d)) {
							d = new Date();
						}
						val = [d.getHours(), d.getMinutes()];
					}

					var am = 'am';
					var h = parseInt(val[0]);

					if(h == 0) {
						h = 12;
					}
					else if(h > 12) {
						h -= 12;
						am = 'pm';
					}
					else if(h == 12) {
						am = 'pm';
					}

					this.set('text', h + ':' + ('' + val[1]).pad(2, '0', 'l') + ' ' + am);
					pickerEl.fireEvent('change');
				}
				else {
					oset.apply(this, arguments);
				}
			};
			pickerEl.get = function(what) {
				if(what == 'value') {
					return this.get('data-value') || new Date().format('%H:%M');
				}else if(what == 'type') {
					return type;
				}
				else {
					return oget.apply(this, arguments);
				}
			};

			pickerEl.set('value', pickerEl.get('data-value') || new Date().format('%H:%M'));

			pickerEl.addEvent('click', function(e) {
				if(appbuilder.app.isIE) {
					// disable ie <= 8
					appbuild.app.alert('This feature is not supported on the browser you are using (Internet Explorer 8). Please try again using IE9, Firefox or Chrome.');
					return;
				}
				
				if(!SpinningWheel.swWrapper) {
					e.stop();
					e.preventDefault();

					appbuilder.app.decoy();

					var val = pickerEl.get('value').split(':');

					var am = 'am';
					var h = val[0];

					if(h == 0) {
						h = 12;
					}
					else if(h > 12) {
						h -= 12;
						am = 'pm';
					}
					else if(h == 12) {
						am = 'pm';
					}

					SpinningWheel.addSlot(hours, 'right', h);
					SpinningWheel.addSlot(mins, '', val[1]);
					SpinningWheel.addSlot(ampm, '', am);

					SpinningWheel.setCancelAction(cancel);
					SpinningWheel.setDoneAction(done);

					SpinningWheel.open();
				}
			});

			done = function() {
				var results = SpinningWheel.getSelectedValues();
				var h = parseInt(results.keys[0]);
				if(h == 12) {
					h = 0;
				}
				if(results.keys[2] == 'pm') {
					h += 12;
				}

				pickerEl.set('value', h + ':' + results.keys[1]);
			};

			cancel = function() {};
		}
	});

	screen.getElements('input[type=text][data-autocomplete-url]:not([data-autocomplete-url=""])').each(function(autocompleteEl) {
		appbuilder.app.debug('list', 'autocomplete');
		var url = autocompleteEl.get('data-autocomplete-url');
		var varName = autocompleteEl.get('data-autocomplete-variable') || autocompleteEl.get('data-variable') || autocompleteEl.get('name');
		var minLength = parseInt(autocompleteEl.get('data-autocomplete-min-length')) || 1;
		var completionsEl = appbuilder.app.makeElement('autocomplete').inject(autocompleteEl, 'after');
		var completionsInner = appbuilder.app.makeElement('autocomplete-inner').inject(completionsEl);
		var scroller = new appbuilder.app.scroll.iphone(completionsEl, {
			onBeforeScrollStart: function (e) {
				if(e.target.tagName != 'INPUT'
					&& e.target.tagName != 'SELECT'
					&& e.target.tagName != 'TEXTAREA') {
					e.preventDefault();
					e.stopPropagation();
				}
			}
		});
		var showCompletions = function(options) {
			completionsInner.empty();
			if(typeOf(options) == 'array' && options.length > 0) {
				options.each(function(op) {
					var e = appbuilder.app.makeElement('completion', {
						'html':op
					}).inject(completionsInner);
					e.addEvent('click', function() {
						autocompleteEl.set('value', op);
						autocompleteEl.fireEvent('change');
						completionsEl.setStyle('display', 'none');
						appbuilder.app.decoy();
					});
				});
				completionsEl.setStyle('height', options.length < 5 ? options.length * 25 : 125);
				completionsEl.setStyle('display', 'block');
				scroller.refresh();
			}
			else {
				completionsEl.setStyle('display', 'none');
			}
		}.bind(this);

		var getCompletions = function(val) {
			this.checkVariables(url, function(url) {
				url = url.toURI().setData(varName, val).toString();
				this.phone.loader.request('autocomplete', url, null, showCompletions);
			}.bind(this));
		}.bind(this);

		autocompleteEl.addEvent('keyup', function() {
			var val = autocompleteEl.get('value');
			if(val.length >= minLength) {
				getCompletions(val);
			}
			else {
				completionsEl.setStyle('display', 'none');
			}
		}.bind(this));

		//formEl.addEvent('blur', function() {
		//	completionsEl.setStyle('display', 'none');
		//});

		autocompleteEl.set('autocorrect', 'off');
		autocompleteEl.set('autocomplete', 'off');
		autocompleteEl.set('autocapitalize', 'off');
	}, this);

	var localSearchBoxes = screen.getElements('input.textbox.localsearch');
	if(localSearchBoxes.length > 0) {
		this.phone.fetch('searchdata', this.phone.history.currentApp, function(searchData) {
			var screenId = screen.get('id').replace('screen' , '');
			localSearchBoxes.each(function(localSearchBox) {
				var results = new Element('div');
				results.inject(localSearchBox.getParent('.item.textbox'), 'after');
				localSearchBox.addEvent('keyup',function() {
					results.set('html', '');
					var resultsArray = [],
						matchesCount = 0,
						searchTerm = localSearchBox.get('value').toLowerCase();
					if(searchTerm.length > 1) {
						Object.each(searchData.words, function(screenIds, key) {
							if(key.indexOf(searchTerm) === 0) {
								screenIds.each(function(id) {
									matchesCount++;
									var resultElement = new Element('div', {
										'class': 'item plain link',
										'data-linktype': 'screen',
										'data-href': id,
										'data-highlight': searchTerm,
										html: '<div class="link-arrow"></div><div class="text">' + searchData.screens[id].substr(searchData.screens[id].toLowerCase().indexOf(searchTerm),35).replace(/(^\s+)|(\s+$)/g, "").replace(searchTerm , '<b>'+searchTerm+'</b>')+'...</div>'
									});
									if(matchesCount < 4) {
										resultElement.inject(results);
									}
									resultsArray.push(resultElement);
								});
							}
						});
						if(matchesCount > 3) {
							var more = new Element('div', {
								'class': 'item plain link',
								html: '<div class="link-arrow"></div><div class="text">See all ' + resultsArray.length + ' results...</div>'
							}).inject(results);
							more.addEvent('click', function(e) {
								e.stop();
								var container = new Element('div');
								resultsArray.each(function(i) {
									i.inject(container);
								});
								this.showScreen(
									appbuilder.app.createScreen('screensearch' + searchTerm, 'screen', 'Results for '+searchTerm, container.get('html'), true, true, screenId, false, 'list'),
									'search' + searchTerm, true, false, false
								);
							}.bind(this));
						}
					}
				}.bind(this));
			}, this);
		}.bind(this));
	}
	// get screen variables
	screen.getElements('[data-variable]').each(function(formEl) {
		appbuilder.app.debug('list', 'variable');
		var id = formEl.get('id'), varName = formEl.get('data-variable') , varTagName = formEl.get('tag'), varType = formEl.get('type') ;
		if(formEl.get('data-save-value') != 'no') {
			var obj = this.loadVar(varName);
			if(obj) {
				if(formEl.get('type') == 'checkbox') {
					formEl.set('checked', formEl.get('value') == obj.value);
				}
                else if (formEl.get('type') == 'radio') {
                    if (formEl.get('value') == obj.value) {
                        formEl.set('checked', true);
                    }
                }
				else {
					formEl.set('value', obj.value);
				}
			}
		}

        var change = function() {
            if(formEl.get('type') == 'checkbox') {
                this.saveVar(varName, formEl.get('checked') ? formEl.get('value') : '', null, varType);
            }
            else if (formEl.get('type') == 'radio') {
                if (formEl.get('checked')) {
                    this.saveVar(varName, formEl.get('value'), null, varType);
                }
            }
            else {
                this.saveVar(varName, formEl.get('value'), null, varType);
            }
        }.bind(this);

		if(!appbuilder.app.isMobile) {
            switch(varTagName) {
				case 'input':
				case 'textarea':
					formEl.addEvent('keyup', change);
					break;
			}
		}
		formEl.addEvent('change', change);
        change();
	}, this);

	screen.getElements('.item.capture').each(function(captureEl) {
		var type = captureEl.get('data-capturetype');
		var on = captureEl.getElement('.on');
		var off = captureEl.getElement('.off');
		var varName = captureEl.get('data-name');

		var canCapture = appbuilder.app.info.canCapture && appbuilder.app.info.canCapture.contains(type);
		var canChoose = appbuilder.app.info.canChoose && appbuilder.app.info.canChoose.contains(type);

		if(canCapture || canChoose) {
			off.destroy();
			var capturebtn = captureEl.getElement('.capture');
			var choosebtn = captureEl.getElement('.choose');
			var icon = captureEl.getElement('.image');

			var captured = function(mediaFiles) {
				setURL('file://' + mediaFiles[0].fullPath);
			};

			var scrollRefresh = function() {
				var items = screen.getElement('.items.scrolling');
				if(items) {
					var s = items.retrieve('scroll');
					if(s) {
						s.refresh();
					}
				}
			};

			var setURL = function(url) {
				this.phone.history.disableRefresh = false;
				var newicon;
				this.saveVar(varName, url, 'post', type);
				if(type == 'audio') {
					newicon = new Element('div', {
						'class':'image'
					});
					var audio = new Element('audio', {
						'src':url
					});
					var playing = false;
					newicon.addClass('play');
					newicon.addEvent('click', function() {
						if(playing) {
							audio.pause();
							newicon.addClass('play');
							newicon.removeClass('pause');
						}
						else {
							audio.play();
							newicon.removeClass('play');
							newicon.addClass('pause');
						}
						playing = !playing;
					});
					newicon.replaces(icon);
					icon = newicon;
				}
				else if(type == 'video') {
					newicon = new Element('video', {
						'class':'image', 
						'src':url,
						'onLoad': scrollRefresh
					});
					newicon.replaces(icon);
					icon = newicon;
				}
				else if(type == 'photo') {
					newicon = new Element('img', {
						'class':'image', 
						'src':url,
						'onLoad': scrollRefresh
					});
					newicon.replaces(icon);
					icon = newicon;
				}
			}.bind(this);

			var failed = function() {
				this.phone.history.disableRefresh = false;
				appbuilder.app.debug('navigator.screen.list', arguments);
			}.bind(this);

			if(!canCapture) {
				capturebtn.destroy();
			}
			else {
				capturebtn.addEvent('click', function() {
					appbuilder.app.decoy();

					if(type == 'photo') {
						this.phone.history.disableRefresh = true;
						navigator.device.capture.captureImage(captured, failed, {
							limit: 1
						});
					}
					else if(type == 'video') {
						this.phone.history.disableRefresh = true;
						navigator.device.capture.captureVideo(captured, failed, {
							limit: 1
						});
					}
					else if(type == 'audio') {
						this.phone.history.disableRefresh = true;
						navigator.device.capture.captureAudio(captured, failed, {
							limit: 1
						});
					}
				}.bind(this));
			}
			if(!canChoose) {
				choosebtn.destroy();
			}
			else {
				choosebtn.addEvent('click', function() {
					appbuilder.app.decoy();

					if(type == 'photo') {
						this.phone.history.disableRefresh = true;
						navigator.camera.getPicture(setURL, failed, {
							quality: 50,
							destinationType: navigator.camera.DestinationType.FILE_URI,
							sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
                            mediaType: navigator.camera.MediaType.PICTURE,
							allowEdit: false
						});
					}
					else if(type == 'video') {
						this.phone.history.disableRefresh = true;
						navigator.camera.getPicture(setURL, failed, {
							quality: 50,
							destinationType: navigator.camera.DestinationType.FILE_URI,
							sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
                            mediaType: navigator.camera.MediaType.VIDEO,
							allowEdit: true
						});
					}
					else if(type == 'audio') {
						this.phone.history.disableRefresh = true;
						navigator.camera.getPicture(setURL, failed, {
							quality: 50,
							destinationType: navigator.camera.DestinationType.FILE_URI,
							sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
                            mediaType: navigator.camera.MediaType.ALLMEDIA,
							allowEdit: true
						});
					}
				}.bind(this));
			}
		}
		else {
			on.destroy();
		}

	}, this);
	
	screen.getElements('.item.marker').each(function(markerEl) {
		appbuilder.app.debug('list', 'marker');
		var lon = markerEl.get('data-longitude');
		var lat = markerEl.get('data-latitude');
		var width = (markerEl.getSize().x - 20);
		var height = 100;
		new Element('img', {
			src: 'http://maps.googleapis.com/maps/api/staticmap?sensor=false&zoom=13&center=' + lat + ',' + lon + '&size=' + width + 'x' + height + '&markers=' + lat + ',' + lon,
			width: width,
			height: height
		}).inject(markerEl);
	}, this);
};
