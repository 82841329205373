appbuilder = window.appbuilder || {};
appbuilder.app = appbuilder.app || {};
appbuilder.app.sound = (function() {
	/* Dection code thanks to Modernizr 2.6.2 (Custom Build) | MIT & BSD
	* Build: http://modernizr.com/download/#-audio
	*/
	var support = function() {
        var elem = document.createElement('audio'),
            bool = false;

        try {
            if ( bool = !!elem.canPlayType ) {
                bool      = new Boolean(bool);
                bool.ogg  = elem.canPlayType('audio/ogg; codecs="vorbis"').replace(/^no$/,'');
                bool.mp3  = elem.canPlayType('audio/mpeg;')               .replace(/^no$/,'');

                bool.wav  = elem.canPlayType('audio/wav; codecs="1"')     .replace(/^no$/,'');
                bool.m4a  = ( elem.canPlayType('audio/x-m4a;')            ||
                              elem.canPlayType('audio/aac;'))             .replace(/^no$/,'');
            }
        } catch(e) { }

        return bool;
    }();
	if(support) {
		var audios = [];
		return {
			support: support,
			play: function(url) {
				var a = new Audio(url);
				audios.push(a);
				a.addEventListener('ended', function() {
					audios.erase(a);
				});
				a.play();
				return a;
			},
			stop: function(a) {
				a.pause();
				a.src = '';
				audios.erase(a);
			},
			stopAll: function() {
				audios.each(function(a) {
					a.pause();
					a.src = '';
				});
				audios = [];
			}
		};
	}
	else {
		return {
			support: false,
			play: function() {
				return false;
			},
			stop: function() {},
			stopAll: function() {}
		};
	}
})();
