appbuilder = window.appbuilder || {};

(function() {
	appbuilder.map = {};
	var NOT_INCLUDED = 0, LOADING = 1, INCLUDED = 2, FAILED = 3;

	var included = window.navigator.offline !== false ? NOT_INCLUDED : FAILED;
	
	var delay;
	
	var failed = function() {
		if(included == LOADING) {
			included = FAILED;
			onInitialize.each(function(cb) {
				cb.delay(0, this, [false]);
			});
			onInitialize = [];
		}
	};

	appbuilder.map.include = function(cb) {
		if(included == INCLUDED) {
			cb(true);
		}
		else if(included == FAILED) {
			cb(false);
		}
		else if(included == LOADING) {
			onInitialize.push(cb);
		}
		else {
			var script = document.createElement("script");
			script.type = "text/javascript";
			script.async = true;
			script.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'maps.googleapis.com/maps/api/js?sensor=false&callback=appbuilder.map.initialize';
			included = LOADING;
			onInitialize.push(cb);
			var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(script, s);
			delay = failed.delay(10000);
		}
	};

	var onInitialize = [];
	appbuilder.map.initialize = function() {
		clearTimeout(delay);
		setupInfoBox();
		included = INCLUDED;
		onInitialize.each(function(cb) {
			cb.delay(0, this, [true]);
		});
		onInitialize = [];
	};
	
	var position = false;
	var requested = false;
	var onPosition = [];
	var failedTimeout;
	
	var after = function(ret) {
		position = ret;
		onPosition.each(function(cb) {
			cb(ret);
		});
		requested = false;
		onPosition = [];
		clearTimeout(failedTimeout);
	};
	
	appbuilder.map.getPosition = function(cb) {
		if(position && (new Date().getTime() - position.timestamp) < 60000) {
			cb(position);
		}
		else if(requested) {
			onPosition.push(cb);
		}
		else if(navigator.geolocation) {
			requested = true;
			onPosition.push(cb);
			navigator.geolocation.getCurrentPosition(function(p) {
				after(p);
			}, function() {
				after(false);
			}, {
				timeout: 10000,
				maximumAge: 60000
			});
			failedTimeout = (function() {
				after(false);
			}).delay(12000);
		}
		else {
			cb(false);
		}
	};
	
})();