appbuilder.app.Navigator.screenSetups = appbuilder.app.Navigator.screenSetups || {};
appbuilder.app.Navigator.screenSetups.appsscreen = function(screen, html) {
	var resize = screen.retrieve('resize');
	if(!resize) {
		var	items = screen.getElements('.apps'),
		inner = screen.getElement('.items-inner');
		resize = function() {
			appbuilder.app.debug('apps', 'resize');
			var s = screen.getSize();
			items.setStyles({
				width: s.x
			});
			inner.setStyles({
				width: s.x * items.length
			});
            var scroll = screen.getElement('.items').retrieve('scroll');
            if (scroll) {
                setTimeout(function() {
                    scroll.gotoPage(scroll.getPage());
                }, 0)
            }
		};
		this.phone.addEvent('resize', resize);
		screen.store('resize', resize);
		
		items.each(function(apps) {
			var title = apps.getElement('.apps-title');
			if(title) {
				var otext = title.get('text'),
					timeout;
				apps.addEvent('mouseover:relay(.item.icon)', function(e) {
					title.set('text', this.getElement('.title').get('text'));
					clearTimeout(timeout);
					timeout = setTimeout(function() {
						title.set('text', otext);
					}, 1000);
				});
			}
		});
		
		var apps = screen.getElements('.item.icon');
		
		var searchBox = screen.getElement('.search-box');
		var searchResults = screen.getElement('.search-results');
		function change() {
			var val = searchBox.get('value');
			searchResults.empty();
			if(val) {
				val = val.toLowerCase();
				var count = 0;
				apps.every(function(app) {
					if(app.getElement('.title').get('text').toLowerCase().contains(val)) {
						app.clone().inject(searchResults);
					}
					return count++ < 5;
				});
			}
		}
		searchBox.addEvent('keyup', change);
		searchBox.onwebkitspeechchange = change;
		
		document.id(this.phone).addClass('apps-screen');
		
		screen.addEvent('destroy', function() {
			appbuilder.app.debug('apps', 'destroy');
			this.phone.removeEvent('resize', resize);
			screen.store('resize', false);
			
			document.id(this.phone).removeClass('apps-screen');
		}.bind(this));
	}
	resize();
};
