appbuilder = window.appbuilder || {};
appbuilder.app = appbuilder.app || {};

appbuilder.app.Storage = {
	db: {},
	remotedbs: {},
	
	keyStacks: {},
	keyStackLimits: {
		app: 5,
		screen: 20,
		image: 100,
		remote: 5,
        inputs: 100
	},
	
	pushKey: function(type, key) {
		var keyStack = this.keyStacks[type] || (this.keyStacks[type] = []);

        if (!keyStack.indexOf(key)) {
            keyStack.push(key);
            if (keyStack.length > (this.keyStackLimits[type] || 5)) {
                delete this.db[keyStack.shift()];
            }
        }
	},
	
	store: function(type, id, value) {
		this.internalStore(type, id, value);
	},
	
	internalStore: function(type, id, value, db) {
		var key = this.getKey(type, id);
		if(!db) {
			this.pushKey(type, key);
			this.db[key] = value;
		}
		else {
			db[key] = value;
		}
	},

	fetch: function(type, id) {
		var key = this.getKey(type, id);
		var db = this.db;
		var value = db[key];
		if(!value) {
			Object.some(this.remotedbs, function(rdb) {
				if(type == 'remote' && db.limit > 0 && new Date().getTime() > rdb.limit) {
					return false;
				}
				return Object.some(rdb, function(v, k) {
					if(k == key) {
						db = rdb;
						value = v;
						return true;
					}
					return false;
				});
			});
		}
		
		return value;
	},
	
	internalFetch: function(type, id, db) {
		var key = this.getKey(type, id);
		return (db || this.db)[key];
	},
	
	clear: function() {
		this.db = {};
	},
	
	storeQueue: [],
	
	flush: function() {
		this.storeQueue.each(function(f) {
			f();
		});
		this.storeQueue = [];
	},
	
	storeObj: function(obj, work) {
		if(!work) {
			this.storeQueue.push(function() {this.storeObj(obj, true)}.bind(this));
			return;
		}
		if(obj.remote) {
			var key = obj.remote.url;
			var keyStack = this.keyStacks.remote || (this.keyStacks.remote = []);
			keyStack.push(key);
			if(keyStack.length > appbuilder.app.Storage.keyStackLimits.remote) {
				delete this.remotedbs[keyStack.shift()];
			}
			this.remotedbs[key] = {};
			if(obj.remote.refreshAfter > 0) {
				this.remotedbs[key].limit = new Date().getTime() + (obj.remote.refreshAfter * 1000);
			}
			else {
				this.remotedbs[key].limit = 0;
			}
			Object.each(obj, function(v, type) {
				if(type == 'app' || type == 'screen') {
					Object.each(v, function(value, id) {
						this.internalStore(type, id, value, this.remotedbs[key]);
					}, this);
				}
				else if(type == 'remote' && (v.refreshAfter > 0 || v.refreshAfter == 'cache')) {
					Object.each(v, function(value, id) {
						this.internalStore(type, id, value, this.remotedbs[key]);
					}, this);
				}
			}, this);
		}
		else {
			Object.each(obj, function(v, k) {
				if(k == 'app' || k == 'screen') {
					Object.each(v, function(value, id) {
						this.internalStore(k, id, value);
					}, this);
				}
				if(k == 'searchdata') {
					Object.each(v, function(value, id) {
						this.internalStore('searchdata', id,value);
					}, this);
				}
			}, this);
		}
	},
	
	getKey: function(type, id) {
		return type + '_' + id;
	}
};



if(window.localStorage) {
	appbuilder.app.LocalStorage = {
		store: function(type, id, value, cb) {
			var ret;
			if(type == 'app' || type == 'screen') {
				ret = this.storeAppScreen(type, id, value);
			}
			else {
				ret = this.internalStore(type, id, value);
			}
			cb && cb(ret);
			return ret;
		},
		
		internalStore: function(type, id, value, time) {
			lscache.set(this.getKey(type, id), value, time || -1);
			return true;
		},
		
		fetch: function(type, id, cb) {
			var value = this.internalFetch(type, id);
			if(value && (type == 'app' || type == 'screen')) {
				value = this.fetchAppScreen(value);
			}
			if(cb) {
				cb(value);
			}
			return value;
		},
		
		clear: function() {
			localStorage.clear();
		},

		
		internalFetch: function(type, id) {
			var value = lscache.get(this.getKey(type, id));
			return value;
		},
		
		storeQueue: [],
	
		flush: function() {
			this.storeQueue.each(function(f) {
				f();
			});
			this.storeQueue = [];
		},

		storeObj: function(obj, work) {
			if(!work) {
				this.storeQueue.push(function() {this.storeObj(obj, true)}.bind(this));
				return;
			}
			Object.each(obj, function(v, k) {
				if(k == 'app' || k == 'screen') {
					Object.each(v, function(value, id) {
						this.storeAppScreen(k, id, value);
					}, this);
				}
				else if(k == 'remote' && v.refreshAfter > 0) {
					Object.each(v, function(value, id) {
						this.internalStore(k, id, value, v.refreshAfter);
					}, this);
				}
				else if(k == 'searchdata') {
					Object.each(v, function(value, id) {
						this.internalStore('searchdata', id,value);
					}, this);
				}
				
			}, this);
		},
		
		storeAppScreen: function(type, id, obj, db) {
			var objCopy = {};
			Object.each(obj, function(v, k) {
				objCopy[k] = v;
			});

			var pattern = /img([^>]+?)src=("|')(http.+?)\2/g, pattern2 = /url\(("|'|)(http.+?)\1\)/g;
			var handleUrl = function(url) {
				if(this.fetch('image', url)) {
					return url;
				}
				else if(appbuilder.app.canGetImageData) {
					var uri = new URI(url),
						name = uri.get('file');
					var storeImg = function() {
						appbuilder.app.getImageDataURL(url, function(data) {
							if(data) {
								this.internalStore('image', url, data, db);
							}
						}.bind(this));
					}.bind(this);
					//Phonegap export first checks to see if the image is locally available
					if((this.phone && this.phone.options.files) || appbuilder.app.isPhoneGap) {
						var im = new Image();
						im.onload = function() {
							this.internalStore('image', url, name, db);
						}.bind(this);
						im.onerror = im.onabort = function() {
							storeImg();
						};
						im.src = name;
					}
					else {
						storeImg();
					}
					return 'appbuilder:' + url;
				}
				return false;
			}.bind(this);

			objCopy.html = obj.html.replace(pattern, function(match, extra, quote, url) {
				var rep = handleUrl(url);
				if(rep) {
					return 'img' + extra + 'src=' + quote + rep + quote;
				}
				return match;
			}).replace(pattern2, function(match, quote, url) {
				var rep = handleUrl(url);
				if(rep) {
					return 'url(' + quote + rep + quote + ')';
				}
				return match;
			});

			this.internalStore(type, id, objCopy, db);
		},

		fetchAppScreen: function(obj, db) {
			var objCopy = {};
			Object.each(obj, function(v, k) {
				objCopy[k] = v;
			});

			var pattern = /img([^>]+?)src=("|')appbuilder:(.+?)\2/g, pattern2 = /url\(("|'|)appbuilder:(.+?)\1\)/g;
			objCopy.html = objCopy.html.replace(pattern, function(match, extra, quote, name) {
				var data = this.internalFetch('image', name, db);
				return 'img' + extra + 'src=' + quote + (data || name)  + quote;
			}.bind(this)).replace(pattern2, function(match, quote, name) {
				var data = this.internalFetch('image', name, db);
				return 'url(' + quote + (data || name) + quote + ')';
			}.bind(this));

			return objCopy;
		}
	};
	
	appbuilder.app.LocalStorage.getKey = appbuilder.app.Storage.getKey;
}
