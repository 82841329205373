appbuilder = window.appbuilder || {};
appbuilder.app = appbuilder.app || {};

appbuilder.app.BBM = {
	bbmSetup: false,
	access: 'unknown',
	cbs: [],
	//Default dev uuid, apps create there own which is setup by the app object
	uuid: "c2b3c8fc-54c5-4de4-a211-8b70eaa519d6",
	
	canBBM: Browser.Platform.BB10 || Browser.Platform.smartphone,
	errorMsg: 'This action is only avaible on BlackBerry devices',
	
	setup: function() {
		if(!this.bbmSetup) {
			appbuilder.app.loadScript('local:///chrome/webworks.js', function() {
				if(blackberry.event) {
					document.addEventListener("webworksready", function() {
						this.bbmSetup = true;
						blackberry.event.addEventListener('onaccesschanged', this.accessChanged.bind(this));
						this.postSetup();
					}.bind(this));
				}
				else {
					this.bbmSetup = true;
					blackberry.bbm.platform.onaccesschanged = this.accessChanged.bind(this);
					this.postSetup();
				}
			}.bind(this), function() {
				this.bbmSetup = true;
				this.canBBM = false;
				this.processCbs();
			}.bind(this));
		}
		else {
			this.postSetup();
		}
	},
	
	postSetup: function() {
		if (this.access == "allowed") {
			this.processCbs();
		}
		else {
			this.register();
		}
	},
	
	register: function() {
		appbuilder.app.debug('bbm', 'register');
		try {
			blackberry.bbm.platform.register({
				uuid: this.uuid
			});
		}
		catch(e) {
			appbuilder.app.alert('uuid error');
		}
	},
	
	accessChanged: function(accessible, status) {
		appbuilder.app.debug('bbm', 'access', status);
		this.access = status;
		if (status == "unregistered") {
			//this.register();
		}
		else if (status == "allowed") {
			this.processCbs();
		}
		// Listen for other status...
	},
	
	processCbs: function() {
		var cbs = this.cbs;
		this.cbs = [];
		cbs.each(function(cb) {
			cb(this.canBBM);
		}, this);
	},
	
	/**
	 * Use this to make calls to BBM, via a callback.
	 * It could be called immeditaly, or it might not be called until the user
	 * has registed the app with BBM
	 * The callback takes an argument `canBBM`, only if its true should the cb procced
	 */
	call: function(cb) {
		this.cbs.push(cb);
		if(this.canBBM) {
			this.setup();
		}
		else {
			this.processCbs();
		}
	},
	
	/**
	 * Share the app
	 */
	shareApp: function(cb, context) {
		appbuilder.app.debug('bbm', 'share');
		this.call(function(canBBM) {
			if(canBBM) {
				blackberry.bbm.platform.users.inviteToDownload();
			}
			else {
				appbuilder.app.alert(this.errorMsg, context);
			}
			cb();
		});
	}
};
