appbuilder = window.appbuilder || {};
appbuilder.app = appbuilder.app || {};
appbuilder.app.ActionHandler = {

    init:function(jsApi){

        this.jsApi = jsApi;
    },


    fireAction: function (action) {

        console.log(action);

        if (action.linkType == 'screen') {
            this.jsApi.showScreen(action.screen);
        }
        else if (action.linkType == 'web') {
            this.jsApi.showWeb(action.href, action.webType);
        }
        else if (action.linkType == 'file') {
            this.jsApi.showFile(action.href);
        }
        else if (action.linkType == 'tab') {
            this.jsApi.showTab(action.tab);
        }
        else if (action.linkType == 'rss') {
            this.jsApi.showRemoteScreen(action.href,action.remoteDirect);
        }
        else if (action.linkType == 'youtube') {
            this.jsApi.showYoutube(action.href);
        }
        else if (action.linkType == 'phone') {
            this.jsApi.tel(action.phone);
        }
        else if (action.linkType == 'email') {
            this.jsApi.email(action.emailTo, action.emailSubject, action.emailBody);
        }
        else if (action.linkType == 'extension') {
            this.jsApi.showRemoteScreen(action.href,action.remoteDirect);
        }
        else if (action.linkType == 'remotexml') {
            this.jsApi.showRemoteScreen(action.href,action.remoteDirect);
        }
        else if (action.linkType == 'facebook') {
            this.jsApi.showFacebook(action.href);
        }
        else if (action.linkType == 'twitter') {
            this.jsApi.showTwitter(action.twitterUsername);
        }
        else if (action.linkType == 'back') {
            this.jsApi.back();
        }
        else if (action.linkType == 'code') {
            if(action.codeType=='js'){
                this.jsApi.js(action.js);
            }else{
                this.jsApi.js(action.blocklyJs);
            }
        }
        else if (action.linkType == 'bbm') {
            this.jsApi.bbm();
        }
        else if (action.linkType == 'sound') {
            this.jsApi.playSound(action.href);
        }
        else if (action.linkType == 'iot') {
            this.jsApi.iot(action.iotVariable, action.iotValue);
        }
        else if (action.linkType == 'video') {
            this.jsApi.showFile(action.href);
        }
        else if (action.linkType == 'vimeo') {
            this.jsApi.showVimeo(action.href);
        }
    }

}