appbuilder = window.appbuilder || {};
appbuilder.app = appbuilder.app || {};

appbuilder.app.FileLoader = {
	delay: false,
	cb: false,
	
	fetch: function(type, id, cb, fetchId, phone) {
		appbuilder.app.debug('fileLoader', 'fetch', type, id);
		this.cb = cb;
		this.type = type;
		this.id = id;
		this.phone = phone;
		if(type == 'remote') {
			id = SHA1(id);
		}
		this.scriptEl = new Element('script', {
			src: (fetchId || id) + '.' + type + '.js',
			type: 'text/javascript',
			async: true,
			onError: this.failed.bind(this)
		});
		this.scriptEl.inject(document.id(document.body));
		this.delay = this.failed.delay(500, this);
	},
	
	fetched: function(obj) {
		appbuilder.app.debug('fileLoader', 'fetched');
		if(this.delay) {
			clearTimeout(this.delay);
		}
		this.delay = false;
		this.scriptEl.destroy();
		
		if(obj && obj[this.type] && obj[this.type][this.id]) {
			if(this.phone) {
				this.phone.loaded(obj, false, true);
			}
			this.cb(obj[this.type][this.id], obj);
		}
		else {
			appbuilder.app.debug('fileloader', 'wrong data', obj);
			this.cb(false);
		}
	},
	
	failed: function() {
		appbuilder.app.debug('fileLoader', 'failed');
		if(this.delay) {
			clearTimeout(this.delay);
		}
		this.delay = false;
		this.scriptEl.destroy();
		this.cb(false);
	}
};
