appbuilder = window.appbuilder || {};
appbuilder.events = appbuilder.events || {};
appbuilder.events.iot = appbuilder.events.iot || {};


(function (window) {

    var eventer_iot = {},
        pinMap = [],
        isActive = false,
        lastPinVals = {};
    listeners = {};
    eventer_iot.lastPinVals = lastPinVals;
    eventer_iot.init = function (iotConfig, map) {
        eventer_iot.iotConfig = iotConfig;
        eventer_iot.pinMap = map;
        if (window.ioio && iotConfig) {
            pinMap = map;
            initPins();
            window.ioio.open(iotConfig,
                function () {
                    isActive = true;
                    console.log('ioio init success');
                }, function () {
                    console.error('ioio init error');
                }, function (vals) {
                    try {
                        porcessPins(vals);
                    } catch (e) {
                        console.log('error', e);
                    }
                });
        }
    };


    function initPins() {
        Object.each(pinMap, function (pin) {
            lastPinVals[pin.pin] = {
                'value': null,
                'direction': pin.direction,
                'type': pin.type,
                'calbacks': pin.type == 'digital' ? [] : {}
            };
        });
    }


    eventer_iot.close = function () {
        if (window.ioio) {
            window.ioio.close();
        }
    };

    eventer_iot.addEvent = function (fn, pinName, val, range) {
        var pin = getPinInfoByName(pinName);

        if (pin && pin.direction == 'input') {
            if (pin.type == 'digital') {
                lastPinVals[pin.pin].calbacks.push(fn);
                listeners[pin.pin] = true;
            } else {
                lastPinVals[pin.pin].calbacks[encodeKey([val, range || 0])] = {cb: fn, 'lastVal': null};
            }

        } else {
            console.error('Add event only for inputs');
        }
    };

    eventer_iot.removeEvent = function (fn, pinName, val, range) {
        var pin = getPinInfoByName(pinName);
        if (pin) {
            if (lastPinVals[pin.pin].type == 'digital') {
                lastPinVals[pin.pin].calbacks.erase(fn);
                delete listeners[pin.pin];

            } else {
                var key = encodeKey([val, range || 0]);
                delete lastPinVals[pin.pin].calbacks[key];
            }
        }
    };

    eventer_iot.setPin = function (pinName, value) {

        if (window.ioio) {
            if (value === 'true') {
                value = true;
            } else if (value === 'false') {
                value = false;
            }

            var pin = getPinInfoByName(pinName);
            if (checkInit() && pin && pin.direction == 'output') {
                if (pin.type == 'digital') {
                    if (typeof value !== 'boolean') {
                        value = (value >= 50) ? true : false;
                    }
                    window.ioio.setDigitalOutput(pin.pin, value);
                } else if (pin.type == 'pwm') {
                    window.ioio.setPwnOutput(pin.pin, percentToValue(pin, value));
                }
            } else {
                console.info('Can set only output pins');
            }
        } else {
            console.info('no ioio plugin');
        }
    };

    eventer_iot.getPinValue = function (pinName) {
        var pin = getPinInfoByName(pinName);
        if (pin && lastPinVals[pin.pin]) {
            return lastPinVals[pin.pin].value;
        }
        return null;
    };

    eventer_iot.togglePinValue = function (pinName) {
        if (window.ioio) {
            var pin = getPinInfoByName(pinName);
            if (checkInit() && pin && pin.direction == 'output') {
                window.ioio.toggleDigitalOutput(pin.pin);
            } else {
                console.info('Only output pins!');
            }
        } else {
            console.info('no ioio plugin');
        }

    };


    porcessPins = function (vals) {

        var pin, lastPin;

        for (var i = 0; i < vals.length; i++) {
            pin = vals[i];
            lastPin = lastPinVals[pin.pin];

            if (lastPin) {
                if (pin.class == window.ioio.PIN_INPUT_DIGITAL) {
                    lastPin.calbacks.forEach(function (fn) {

                        if (listeners[pin.pin] || (pin.value != lastPin.value)) {
                            fn(pin.value);
                            listeners[pin.pin] = false;
                        }

                    });
                } else if (pin.class == window.ioio.PIN_INPUT_ANALOG) {
                    Object.each(lastPin.calbacks, function (obj, key) {

                        var keyArr = decodeKey(key);
                        var pointVal = parseInt(keyArr[0]);
                        var range = parseInt(keyArr[1]);
                        var pinInfo = getPinInfoByPin(pin.pin);

                        var currentRealValue = percentToValue_(pinInfo, pin.value);

                        if (range) {
                            //var lastInRange = (lastPin.value == pointVal || ( ((pointVal - range) <= lastPin.value) && (lastPin.value <= (pointVal + range))));
                            var nowInRange = ( ((pointVal - range) <= currentRealValue) && (currentRealValue <= (pointVal + range)));
                            if ((obj['lastVal'] === null) || (obj['lastVal'] != nowInRange)) {
                                obj.cb(currentRealValue);
                                obj['lastVal'] = nowInRange;
                            }
                        } else {

                            if ((obj['lastVal'] === null) || (obj['lastVal'] != (currentRealValue>pointVal))) {
                                obj.cb(currentRealValue);
                                obj['lastVal'] = currentRealValue>pointVal;
                            }
                        }

                    });
                }


                if (pin.class == window.ioio.PIN_OUTPUT_PWM) {
                    lastPin.value = pin.freq;
                } else if (pin.class == window.ioio.PIN_INPUT_DIGITAL || pin.class == window.ioio.PIN_OUTPUT_DIGITAL) {
                    lastPin.value = pin.value;
                }
            }
        }
    };


    function getPinInfoByName(name) {
        return pinMap[name];
    }

    function getPinInfoByPin(pin) {
        var info = false;
        Object.keys(pinMap).forEach(function (key) {
            if (pinMap[key].pin == pin) {
                info = pinMap[key];
            }
        });
        return info;
    }

    function percentToValue(pin, percent) {
        pin.min = pin.min || 0;
        pin.max = pin.max || 100;
        return Math.ceil(pin.min + ((pin.max - pin.min) / 100 * percent));
    }

    function valueToPercent(pin, value) {
        pin.min = pin.min || 0;
        pin.max = pin.max || 100;
        return Math.ceil((pin.max - pin.min) * value);
    }

    function percentToValue_(pin, value) {
        //it makes 
        //{ min: -102, max: 100, val: 0.5% } = val is -1
        //{ min: -101, max: 100, val: 0.5% } = val is 0
        //{ min: -100, max: 100, val: 0.5% } = val is 0
        //{ min: -99,  max: 100, val: 0.5% } = val is 1
        //{ min: -98,  max: 100, val: 0.5% } = val is 1
        //{ min: 0,    max: 100, val: 0.5% } = val is 50
        //{ min: 1,    max: 100, val: 0.5% } = val is 51
        //{ min: 2,    max: 100, val: 0.5% } = val is 51
        //{ min: 50,   max: 100, val: 0.5% } = val is 75

        pin.min = pin.min || 0;
        pin.max = pin.max || 100;
        var val = Math.ceil((pin.max - pin.min) * value) + pin.min

        return val;
    }

    function checkInit() {
        if (!isActive) {
            console.info('Call first init(Config, pinmap)!!');
        }
        return isActive;
    }

    function encodeKey(arr) {
        return arr.join("|");
    }

    function decodeKey(key) {
        return key.split('|');
    }

    function getPinClass(pin) {
        return pin.type + pin.pinDirection.capitalize()
    }

    window.appbuilder.events.iot = eventer_iot;
})(window);
