appbuilder = window.appbuilder || {};
appbuilder.app = appbuilder.app || {};
appbuilder.app.scroll = appbuilder.app.scroll || {};
appbuilder.app.scroll.canIScroll = iScroll.canIScroll();

if(appbuilder.app.scroll.canIScroll) {
	appbuilder.app.debug('scroll', 'iscroll', 'yes');
	appbuilder.app.scroll.iphone = new Class({
		iScrollOptions : {
			hScroll: false,
			hideScrollbar: true,
			onBeforeScrollStart: function (e) {
				if(e.target.tagName != 'INPUT'
				&& e.target.tagName != 'SELECT'
			    && e.target.tagName != 'TEXTAREA') {
					e.preventDefault();
				}
				else {
					return false;
				}
			}
		},
		enabled: true,

		initialize : function(element, options) {
			this.iScroll = new iScroll(element, Object.merge(this.iScrollOptions, options || {}));
		},
		
		refresh : function() {
			appbuilder.app.debug('scroll', 'refresh');
			this.iScroll.refresh();
		},
		
		scrollToElement: function(e, t) {
			this.iScroll.scrollToElement(e, t);
		},
		
		scrollToTop: function() {
			this.iScroll.scrollTo(0, 0);
		},
		
		isScrolling : function() {
			return !this.iScroll.isReady();
		},
		
		setEnabled: function(enabled) {
			this.enabled = enabled;
			if(enabled) {
				this.iScroll.enable();
			}
			else {
				this.iScroll.disable();
			}
		},	
				
		disable:function(){
			this.iScroll.disable();
		},
		
		enable:function(){
			this.iScroll.enable();
		}
	}, 'scroll.iphone-iscroll');
	
	appbuilder.app.scroll.gallery = new Class({
		Extends: appbuilder.app.scroll.iphone,
		iScrollOptions : {
			hScroll: true,
			hScrollbar : false,
			vScroll: false,
			momentum: false,
			snap : '.item.gallery',
			zoom: true
		},
		
		refresh: function() {
			this.parent();
		},

		gotoPage : function(number) {
			this.iScroll.scrollToPage(number, 0, 1);
		},

		getPage : function() {
			return this.iScroll.currPageX;
		}
	}, 'scroll.gallery-iscroll');

	appbuilder.app.scroll.appsscreen = new Class({
		Extends: appbuilder.app.scroll.gallery,
		iScrollOptions : {
			snap : '.apps'
		},
		initialize : function(element, options) {
			var dots = element.getElements('.home-dots .dot');
			var currentPage = 1;
			var homeUnderlay = element.getElement('.home-underlay');
			var setopacity = function(iscroll, xx) {
				if(currentPage == 1 || currentPage == 0) {
					var width = iscroll.wrapperW;
					var x = -xx;
					x = Math.min(x, width);
					x = Math.max(x, 0);
					var opacity = (1 - (x / width)) * 0.6;
					homeUnderlay.setStyle('opacity', opacity);
				}
			};
			this.iScroll = new iScroll(element, Object.merge(this.iScrollOptions, {
				onScrollEnd: (function() {
					dots.removeClass('curr');
					currentPage = this.getPage();
					dots[currentPage].addClass('curr');
				}).bind(this),
				onScrollMove: function(e) {
					setopacity(this, this.x);//this is iScroll
				},
				onTouchEnd: function(e) {
					setopacity(this, this.pagesX[this.currPageX]);//this is iScroll
				}
			}, options || {}));
			this.gotoPage(1);
		}
	}, 'scroll.apps-iscroll');
}
else {
	appbuilder.app.debug('scroll', 'iscroll', 'no');
	var drag = new Class({
		Extends : Drag,
		start : function(e) {
			if (e.target.nodeName != 'SELECT' && e.target.nodeName != 'INPUT' && e.target.nodeName != 'TEXTAREA') {
				this.parent(e);
			}
		}
	});

	appbuilder.app.scroll.iphone = new Class({
		element : null,
		drag : null,
		dragging : false,
		modifiers: {
			x: null,
			y: 'top'
		},
		enabled: true,
		
		initialize : function(element) {
			this.element = element;
			this.inner = element.getFirst();
			var elementSize = this.element.getSize();
			var innerSize = this.inner.getSize();
			var pos = this.inner.getStyle('position');
			if(pos != 'absolute' && pos != 'fixed' && pos != 'relative') {
				this.inner.setStyle('position', 'relative');
			}
			this.drag = new drag(this.inner, {
				handle: this.element,
				style : true,
				modifiers: this.modifiers,
				invert : false,
				preventDefault : false,
				limit: {
					y: [-innerSize.y + elementSize.y, 0],
					x: [-innerSize.x + elementSize.x, 0]
				}
			});

			this.drag.addEvent('start', (function() {
				this.dragging = true;
			}).bind(this));

			this.drag.addEvent('complete', (function() {
				(function() {
					this.dragging = false;
				}).delay(100, this);
			}).bind(this));

			this.element.addEvent('mousewheel', (function(e) {
				e.stop();
				this.scrollWheel(e.wheel);
				this.dragging = true;
				if(this.mouseTimeout > 0) {
					clearTimeout(this.mouseTimeout);
				}
				this.mouseTimeout = (function() {
					this.drag.fireEvent('complete')
				}).delay(100, this);
			}).bind(this));
			this.makeScrollBar();
		},
		
		scrollWheel : function(wheel) {
			if(this.enabled) {
				var top = this.inner.getStyle('top').toInt() || 0;
				if(wheel > 0) {
					top -= 20;
					if(top < this.drag.options.limit.y[0]) {
						top = this.drag.options.limit.y[0]
					}
				}
				else if(wheel < 0) {
					top += 20;
					if(top > this.drag.options.limit.y[1]) {
						top = this.drag.options.limit.y[1];
					}
				}
				this.inner.setStyles({top: top});
			}
		},
		
		makeScrollBar : function() {
			this.scrollBar = new Element('div', {
				'class':'scroll-bar'
			}).inject(this.element);
			this.scrollBar.fade('hide');
			this.scrollKnob = new Element('div', {
				'class':'scroll-knob'
			}).inject(this.element);
			this.scrollKnob.fade('hide');
			var that = this;
			this.drag.addEvent('start', function() {
				that.scrollBar.fade('in');
				that.scrollKnob.fade('in');
			});
			this.drag.addEvent('drag', function() {
				var height = that.element.getStyle('height').toInt();
				var scroll = that.element.getScroll();
				var sT = scroll.y + (0.05 * height);
				var sH = height * 0.9;
				that.scrollBar.setStyle('top', sT);
				that.scrollKnob.setStyle('top', sT + (sH * (scroll.y / height)));
				that.scrollBar.setStyle('right', 2 - scroll.x);
				that.scrollKnob.setStyle('right', 2 - scroll.x);
			});
			this.drag.addEvent('complete', function() {
				that.scrollBar.fade('out');
				that.scrollKnob.fade('out');
			});
		},
		
		scrollToElement: function(e, t) {
			this.inner.setStyle('top', Math.max(-e.getPosition(this.inner).y, this.drag.options.limit.y[0]));
		},
		
		refresh : function() {
			var elementSize = this.element.getSize();
			var innerSize = this.inner.getSize();
			this.drag.setOptions({
				limit: {
					y: [-innerSize.y + elementSize.y, 0],
					x: [-innerSize.x + elementSize.x, 0]
				}
			});
		},
		
		scrollToTop: function() {
			this.inner.setStyle('top', 0);
		},
		
		isScrolling : function() {
			return this.dragging;
		},
		
		setEnabled: function(enabled) {
			this.enabled = enabled;
			if(enabled) {
				this.drag.attach();
			}
			else {
				this.drag.detach();
			}
		}
	}, 'scroll.iphone');
	
	appbuilder.app.scroll.gallery = new Class({
		Extends: appbuilder.app.scroll.iphone,
		modifiers: {
			x: 'left',
			y: null
		},
		initialize : function(element) {
			this.parent(element);
			this.drag.addEvent('complete', (function() {
				var left = - this.inner.getStyle('left').toInt();
				var width = this.element.getSize().x;

				var x = Math.floor(left / width) * width;

				if((left % width) > (width / 2)) {
					x += width;
				}

				this.inner.setStyles({left: - x});
			}).bind(this));
		},
		
		scrollWheel : function(wheel) {
			if(this.enabled) {
				var left = this.inner.getStyle('left').toInt() || 0;
				if(wheel > 0) {
					left -= 20;
					if(left < this.drag.options.limit.x[0]) {
						left = this.drag.options.limit.x[0]
					}
				}
				else if(wheel < 0) {
					left += 20;
					if(left > this.drag.options.limit.x[1]) {
						left = this.drag.options.limit.x[1]
					}
				}
				this.inner.setStyles({left: left});
			}
		},
		
		scrollToTop: function() {
			this.inner.setStyle('left', 0);
		},
		
		gotoPage : function(number) {
			this.inner.setStyles({left: -number * this.element.getSize().x});
		},

		getPage : function() {
			var x = -this.inner.getStyle('left').toInt();
			return Math.floor(x / this.element.getSize().x);
		},

		makeScrollBar : function() {

		}
	}, 'scroll.gallery');
	
	appbuilder.app.scroll.appsscreen = new Class({
		Extends: appbuilder.app.scroll.gallery,
		initialize : function(element, options) {
			this.parent(element, options);
			var dots = element.getElements('.home-dots .dot');
			var currentPage = 1;
			var homeUnderlay = element.getElement('.home-underlay');
			var setopacity = function() {
				if(currentPage == 1 || currentPage == 0) {
					var width = this.element.getSize().x;
					var x = -this.inner.getStyle('left').toInt();
					x = Math.min(x, width);
					x = Math.max(x, 0);
					var opacity = (1 - (x / width)) * 0.6;
					homeUnderlay.setStyle('opacity', opacity);
				}
			}.bind(this);
			this.drag.addEvent('complete', (function() {
				dots.removeClass('curr');
				currentPage = this.getPage();
				dots[currentPage].addClass('curr');
				setopacity();
			}).bind(this));
			
			this.drag.addEvent('drag', function() {
				setopacity();
			});
			this.gotoPage(1);
		}
	}, 'scroll.apps');
}
