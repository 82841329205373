/*
 * Shake plugin - https://github.com/leecrossley/cordova-plugin-shake
 * Location and compass - default cordova plugins 
 * http://docs.phonegap.com/en/edge/cordova_plugins_pluginapis.md.html#Plugin%20APIs
 */

appbuilder = window.appbuilder || {};
appbuilder.events = appbuilder.events || {};
appbuilder.events.device = appbuilder.events.device || {};

//window.positionWatchers = [];
//window.navigator.geolocation.watchPosition = function(cb){  window.positionWatchers.push(cb); return window.positionWatchers.length;  };
//window.navigator.geolocation.clearWatch = function(cb){  window.positionWatchers.length = 0;  };
//

(function (window) {

    var eventer_device = {};

// shake events
    var shakersCallbacks = [],
        shakerInWork = false;

    eventer_device.addShakeEvent = function (callback) {
        shakersCallbacks.push(callback);
        startShake();
    };

    eventer_device.removeShakeEvent = function (callback) {
        shakersCallbacks = removeFromArray(shakersCallbacks, callback);
        if (shakersCallbacks.length == 0) {
            if (window.shake && shake && shakerInWork) {
                shake.stopWatch();
            }
        }
    };

    function startShake() {
        var shakeCb = function () {
            shakersCallbacks.forEach(function (fn) {
                fn();
            });
        };

        if (window.appbuilder.preview) {
            shakerInWork = true;
            window.appbuilder.preview.shakeCb = shakeCb;
        } else if (window.shake && shake && !shakerInWork) {
            shake.startWatch(shakeCb, 40, function (e) {
                console.log('shake error', e);
            });
            shakerInWork = true;
        } else {
            console.log('No Shake plugin');
        }
    }

// compass events
    var compassCallbacks = {},
        lastCompass = false,
        compassWatchId = false,
        compassCalback = function (heading) {
            if (heading.alpha) {

                Object.keys(compassCallbacks).forEach(function (key) {
                    var key_arr = decodeKey(key);
                    var point = parseFloat(key_arr[0]);
                    var range = parseFloat(key_arr[1]);
                    var lastPos = lastCompass >= 0 ? isInCompassRange(point, range, lastCompass) : false;
                    var newPos = isInCompassRange(point, range, heading.alpha);
                    compassCallbacks[key].forEach(function (listener) {
                        if (( listener['new'] && newPos ) || (lastPos != newPos)) {
                            listener['new'] = false;
                            listener.cb(newPos);
                        }
                    });
                });
                lastCompass = heading.alpha;
            }
        };

    eventer_device.addCompassEvent = function (callback, position, range) {
        if (position >= 360 || position < 0 || range > 360 || range < 0) {
            console.log('fail values');
            return false;
        } else {
            var key = encodeKey([position, range]);
            if (!compassCallbacks[key]) {
                compassCallbacks[key] = [];
            }
            compassCallbacks[key].push({'new': true, cb: callback});
            if (!compassWatchId) {
                startCompassWatch();
            }
        }
    };

    eventer_device.removeCompassEvent = function (callback, position, range) {
        var key = encodeKey([position, range]);
        if (compassCallbacks[key]) {
            compassCallbacks[key] = removeFromArray(compassCallbacks[key], callback);
            if (compassCallbacks[key].length == 0) {
                delete compassCallbacks[key];
            }
        }
        if (Object.keys(compassCallbacks).length == 0 && compassWatchId) {
            window.removeListener('deviceorientation', compassCalback);
            compassWatchId = false;
        }
    };

    startCompassWatch = function () {
        if (window.appbuilder.preview) {
            compassWatchId = true;
            window.appbuilder.preview.compassCb = compassCalback;
        } else if (window.DeviceOrientationEvent) {
            compassWatchId = true;
            window.addEventListener("deviceorientation", compassCalback, false);
        } else {
            console.log('No Compass');
        }
    };


    isInCompassRange = function (point, range, position) {
        var afterZero = false,
            beforeZero = false;
        if ((point + range) > 360) {
            afterZero = (point + range) - 360;
        }
        if ((point - range) < 0) {
            var tmp = (point - range) < 0 ? (point - range) * -1 : (point - range);
            beforeZero = 360 - tmp;
        }

        if (afterZero) {
            if ((position >= (point - range) ) || ( position <= afterZero )) {
                return true;
            } else {
                return false;
            }
        } else if (beforeZero) {
            if ((position >= beforeZero) || ( position <= (point + range) )) {
                return true;
            } else {
                return false;
            }
        } else {
            if ((position >= (point - range)) && (position <= (point + range))) {
                return true;
            } else {
                return false;
            }
        }
    };

// location events
    var locationsCallbacks = {},
        lastLocation = false,
        locationWatchId = false;

    eventer_device.addLocationEvent = function (callback, lat, lon, method, needed, range) {
        var key = encodeKey([lat, lon, method, needed, range]);
        if (!locationsCallbacks[key]) {
            locationsCallbacks[key] = [];
        }
        locationsCallbacks[key].push({'new': true, cb: callback, 'lastValue': 0});
        if (!locationWatchId) {
            startLocationWatch();
        }
    };

    eventer_device.removeLocationEvent = function (callback, lat, lon, method, needed, range) {
        var key = encodeKey([lat, lon, method, needed, range]);
        if (locationsCallbacks[key]) {
            locationsCallbacks[key] = removeFromArray(locationsCallbacks[key], callback);
            if (locationsCallbacks[key].length == 0) {
                delete locationsCallbacks[key];
                if (Object.keys(locationsCallbacks).length == 0 && locationWatchId) {
                    navigator.geolocation.clearWatch(locationWatchId);
                    locationWatchId = false;
                }
            }
        }
    };

    startLocationWatch = function () {
        try {
            if (window.navigator && navigator.geolocation) {
                locationWatchId = navigator.geolocation.watchPosition(function (position) {
                        Object.keys(locationsCallbacks).forEach(function (key) {
                            var keyArr = decodeKey(key);
                            var pointLat = parseFloat(keyArr[0]);
                            var pointLon = parseFloat(keyArr[1]);
                            var method = keyArr[2];
                            var range = parseFloat(keyArr[4]);

                            var lastPos = lastLocation ? isInLocationRange(lastLocation.latitude, lastLocation.longitude, pointLat, pointLon, range, method) : false;
                            var newPos = isInLocationRange(position.coords.latitude, position.coords.longitude, pointLat, pointLon, range, method);

                            locationsCallbacks[key].forEach(function (listener) {
                                if ((listener['new'] && newPos) || (lastPos != newPos)) {
                                    listener['new'] = false;
                                    listener.cb(newPos);
                                }
                            });
                        });
                        lastLocation = position.coords;
                    }, function (err) {
                        console.log('Location error: ');
                        console.error(err);
                        locationWatchId = false;
                    },
                    {maximumAge: 3000, timeout: 5000, enableHighAccuracy: true});
            } else {
                console.log('No Location plugin');
            }
        } catch (e) {
            console.log(e);
        }
    };

    isInLocationRange = function (locationLat, locationLon, pointLat, pointLon, range, method) {
        var actual = distance(locationLat, locationLon, pointLat, pointLon)
        //console.log('location (lat '+locationLat+' lng '+locationLon+') <-('+_distance+', '+range+','+(result?'true':'false')+'  )-> ( lat '+pointLat+' lng'+pointLon+') ');

        if (((method == 'equal') && (actual < range))) {
            return true;
        } else if ((method == 'notequal') && (actual > range)) { // =
            return true;
        }
        return false;
    };


    function encodeKey(arr) {
        return arr.join("|");
    }

    function decodeKey(key) {
        return key.split('|');
    }

    function distance(lat_1, long_1, lat_2, long_2) {
        //Earth radius
        var R = 6372795;

        var lat1 = lat_1 * Math.PI / 180;
        var lat2 = lat_2 * Math.PI / 180;
        var long1 = long_1 * Math.PI / 180;
        var long2 = long_2 * Math.PI / 180;

        var cl1 = Math.cos(lat1);
        var cl2 = Math.cos(lat2);
        var sl1 = Math.sin(lat1);
        var sl2 = Math.sin(lat2);
        var delta = long2 - long1;
        var cdelta = Math.cos(delta);
        var sdelta = Math.sin(delta);

        var y = Math.sqrt(Math.pow(cl2 * sdelta, 2) + Math.pow(cl1 * sl2 - sl1 * cl2 * cdelta, 2));
        var x = sl1 * sl2 + cl1 * cl2 * cdelta;
        var ad = Math.atan2(y, x);
        var dist = ad * R;//*0.6213;
        return dist.toFixed(3);
    }

    function removeFromArray(arr, item) {
        if (arr.length) {
            var tmp = [];
            arr.forEach(function (itm) {
                if (itm !== item) {
                    tmp.push(itm);
                }
            });
            return tmp;
        } else {
            return arr;
        }
    }

    window.appbuilder.events.device = eventer_device;
})(window);
