appbuilder = window.appbuilder || {};
appbuilder.app = appbuilder.app || {};
appbuilder.app.Loader = new Class({
	
	initialize: function(phone, message) {
		this.phone = phone;
		this.message = message || 'Loading...';
	},
	
	toElement: function() {
		if(!this.element) {
			this.element = appbuilder.app.makeElement('loader');
			var inner = appbuilder.app.makeElement('inner').inject(this.element);
			this.messageEl = appbuilder.app.makeElement('text', {text: this.message}).inject(inner);
			//this.loadingAni = appbuilder.app.makeElement('loading-ani').inject(inner);
			this.loadingAni = appbuilder.app.spinner().inject(inner);
			this.hideLoading();
		}
		return this.element;
	},
	
	setMessage: function(message) {
		this.message = message;
		if(this.messageEl) {
			this.messageEl.set('text', message);
		}
	},
	
	showTimeout: false,
	
	showLoading: function() {
		this.showTimeout = (function() {
			document.id(this);
			this.element.setStyle('display', 'block');
			this.showTimeout = false;
		}).delay(100, this);
	},
	
	hideLoading: function() {
		if(this.showTimeout) {
			clearTimeout(this.showTimeout);
			this.showTimeout = false;
		}
		document.id(this);
		this.element.setStyle('display', 'none');
	},
	
	showLoadingAni: function() {
		this.loadingAni.setStyle('display', 'block');
	},
	
	hideLoadingAni: function() {
		this.loadingAni.setStyle('display', 'none');
	},
	
	fetch: function(type, id, cb, update, fetchId, postVars) {
		var fetchedType = type == 'direct' ? 'remote' : type,
			loaded = function(obj) {
				if(obj[fetchedType] && obj[fetchedType][id]) {
					appbuilder.app.debug('loader', 'fetched', 'loaded');
					this.phone.loaded(obj);
					cb(obj[fetchedType][id], obj);
				}
				else if(!update) {
					appbuilder.app.error('loader', 'fetched', 'incorrect');
					error('Incorrect data fetched');
				}
				else {
					appbuilder.app.debug('loader', 'fetched', 'nothing');
					cb(false);
				}
			}.bind(this),
			error = function(msg) {
				appbuilder.app.error('loader', 'Fetch Error: ' + msg + ' type:' + type + ' id:' + id);
				cb(false);
				cb = function() {};//prevent second call, eg timeout then error
			};
		appbuilder.app.debug('loader', 'fetch', type, id);
		this.request(type, fetchId || id, update, loaded, error, postVars);
	},
	
	request: function(type, id, update, loaded, error, postVars, timeout) {
		var first = true, requestKey, request, r;
		error = error || function() {};
		if(appbuilder.app.isPhoneGap && postVars && postVars.length > 0) {
			postVars.each(function(inp) {
				var uri, data, options = new FileUploadOptions();
				options.fileKey = inp.name;
				options.fileName = inp.value.substr(inp.value.lastIndexOf('/')+1);
				options.mimeType = inp.mimeType;
				options.param = {};

				if(type == 'direct') {
					uri = new URI(id);
					data = uri.getData();
					Object.merge({
						fetchURL: id
					}, data, appbuilder.app.info);
				}
				else {
					uri = new URI(this.phone.options.request.url);
					data = uri.getData();
					Object.merge(data, appbuilder.app.info, this.phone.options.request.data, {
						type: type,
						id: id,
						lastupdate: update
					});
				}
				uri.setData(data);

				var ft = new FileTransfer();
				ft.upload(inp.value, uri.toString(), function(res) {
					if(first) {
						first = false;
						if(res && res.response) {
							loaded(JSON.decode.attempt(decodeURIComponent(response), true));
						}
						else {
							error('file transfer error');
						}
					}
				}, function() {
					if(first) {
						first = false;
						error('file transfer error');
					}
				}, options);
			}, this);
		}
		else {
			requestKey = type.toString() + id.toString(),
			r = this.getRequestQueue().requests[requestKey];

			if (r && r.running) {
				r.addEvent('onSuccess', loaded);
				r.addEvent('onFailure', function() {error('Connection Failure');});
				r.addEvent('onTimeout', function() {error('Timeout');});
			}
			else {//Otherwise make a new one
				request = new (appbuilder.app.hasCORS ? Request.JSON : Request.JSONP)(Object.merge(type == 'direct' ? {
					url: id,
					data: Object.merge({
						fetchURL: id
					}, appbuilder.app.info)
				} : {
					url: this.phone.options.request.url,
					data: Object.merge({}, appbuilder.app.info, this.phone.options.request.data, {
						type: type,
						id: id,
						lastupdate: update
					}, (appbuilder.app.hasCORS ? {} : {
                        rand: (new Date()).getTime()
                    }))
				}, {
					method: this.phone.options.request.method,
					noCache : true,
					timeout: timeout || this.phone.options.request.timeout,
					onSuccess: loaded,
					onFailure: function() {error('Connection Failure');},
					onTimeout: function() {error('Timeout');}
				}));
				this.getRequestQueue().addRequest(requestKey, request);
				request.send();
			}
		}
	},
	
	getRequestQueue : function() {
		if(!this.requestQueue) {
			this.requestQueue = new Request.Queue({
				concurrent : this.phone.options.request.concurrent,
				stopOnFailure : false
			});
		}
		return this.requestQueue;
	}
}, 'Loader');
