appbuilder = window.appbuilder || {};
appbuilder.app = appbuilder.app || {};
appbuilder.app.Navigator = new Class({
    initialize: function(phone) {
        appbuilder.app.debug('navigator', 'initialize');
        this.phone = phone;
        this.afterSearch = false;
        this.appObj = false;
        this.searchword = null;
        this.jsAPI = new appbuilder.app.JSAPI(phone);
        this.events = new appbuilder.app.Events(this.jsAPI);
    },
    toElement: function() {
        if (!this.element) {
            this.element = appbuilder.app.makeElement('phone-navigator');
            SpinningWheel.container = this.element;
            appbuilder.app.link = function(type, id) {
                if (type === 'app') {
                    this.linktypes.app.call(this, id, true, null, null, true);
                }
                else {
                    this.linktypes[type].call(this, id);
                }
            }.bind(this);
            this.bind(this.phone.element);
        }
        return this.element;
    },
    bind: function(el) {
        appbuilder.app.debug('navigator', 'bind');
        el.addEvent((window.location.host === '' ? 'ontouchend' in document ? 'touchend' : 'click' : 'click') + ':relay([data-linktype])', this.click.bind(this));
        el.addEvent('click:relay(a[href])', this.linkCatcher.bind(this));
    },
    enabled: true,
    linkCatcher: function(e, t) {
        if (this.enabled !== true && Date.now() - this.enabled < 5000) {
            return;
        }
        t = document.id(t);
        if (!t.get('data-ignore')) {
            e.stop();
            appbuilder.app.debug('navigator', 'linkCatcher');
            this.linktypes.web.apply(this, [t.get('href'), true]);
        }
    },
    click: function(e, t) {
        if (this.enabled !== true && Date.now() - this.enabled < 5000) {
            return;
        }
        if (e) {
            e.stop();
        }

        this.enabled = Date.now();
        var linktype = t.get('data-linktype');
        var animate = true;

        this.moveAdsInToBody();

        if (t.hasClass('tab')) {
            this.element.getElements('.tab.selected').removeClass('selected');
            t.addClass('selected');
            if (this.tabBar) {
                this.tabBar.removeClass('bbShow');
            }

            animate = false;
            this.phone.history.clearHistory();
            this.phone.fireEvent('tab', [t.get('id').substr(3), t]);
        }
        else if (t.hasClass('back')) {
            if (this.tabBar) {
                this.tabBar.removeClass('bbShow');
            }
            t.addClass('glow-back');
        }
        else {
            var items = t.getParent('.items');
            if (items) {
                var scroll = items.retrieve('scroll');
                if (scroll) {
                    if (scroll.isScrolling()) {
                        return;
                    }
                }
            }
            if (linktype == 'refresh') {
                t.addClass('glow-refresh');
            }
            else if (!t.get('data-no-glow')) {
                appbuilder.app.makeElement('glow').inject(t);
            }
        }
        var highlight = t.get('data-highlight');
        if (highlight) {
            this.afterSearch = true;
            this.searchword = highlight;
        }
        //Delay so the glow becomes visable
        if (this.linktypes[linktype]) {
            appbuilder.app.debug('navigator', 'click', 'delay', '#' + t.get('id') + ' ' + t.get('class'));

            var callEventHandler = function() {
                appbuilder.app.debug('navigator', 'click', linktype);
                this.linktypes[linktype].apply(this, [t, animate]);
            }.bind(this);

            if (linktype === 'web' || linktype === 'sound') {
                callEventHandler();
            } else {
                callEventHandler.delay(1);
            }
        }
        else {
            appbuilder.app.debug('navigator', 'click', 'unknown', linktype, '#' + t.get('id') + ' ' + t.get('class'));
        }
    },
    postLink: function() {
        appbuilder.app.debug('navigator', 'postLink');
        this.element.getElements('.glow').destroy();
        this.element.getElements('.glow-back').removeClass('glow-back');
        this.element.getElements('.glow-refresh').removeClass('glow-refresh');
        this.enabled = true;
        this.phone.flushStorage();
    },
    linktypes: {
        app: function(t, animate, after, appUrl, force) {
            var href = typeOf(t) == 'element' ? t.get('data-href') : t;
            appbuilder.app.debug('navigator', 'link', 'app', href);
            if (href == '1') {
                // when editinng where is disabled home button
                if (!force && document.id('disable-home-button')) {
                    return;
                }
            }
            appbuilder.app.sound.stopAll();
            this.phone.history.currentApp = href;
            this.phone.history.currentAppUrl = appUrl;
            this.phone.history.clearHistory(true);
            this.phone.updateApp(href, function() {
                this.phone.fetch('app', href, function(html) {
                    this.showApp(html, animate, after, href, false);
                }.bind(this), null, appUrl);
            }.bind(this), appUrl);
        },
        tab: function(t) {
            var href = typeOf(t) == 'element' ? t.get('data-href') : t;
            appbuilder.app.debug('navigator', 'link', 'tab', href);
            var tab = this.app.getElement('#tab' + href);
            if (tab) {
                this.enabled = true;
                this.click(null, tab);
            }
            else {
                this.postLink();
            }
            this.phone.history.currentTabId = href;

        },
        login: function(t, animate, back, historyChange, fetchId, fetchUrl) {
            var href, element;
            if (typeOf(t) == 'element') {
                href = t.get('data-href');
                element = t;
            }
            else {
                href = t;
            }
            appbuilder.app.debug('navigator', 'link', 'login', href);
            this.checkVariables(href, function(href) {
                var next = window.localStorage['loginto'];
                var error = function(msg) {
                    this.enabled = true;
                    this.postLink();
                    appbuilder.app.makeConfirm(msg, null, null, null, false).inject(document.id(this));
                }.bind(this);
                new Request.JSON({
                    url: href,
                    data: {
                        appid: this.phone.history.currentApp,
                        screenid: next
                    },
                    method: this.phone.options.request.method,
                    noCache: true,
                    timeout: this.phone.options.request.timeout,
                    onSuccess: function(data) {
                        if (data.ok) {
                            window.localStorage['login-' + next] = (new Date().getTime() / 1000) + data.session_time;
                            this.phone.fetch('screen', next, function(html) {
                                this.showScreen(html, next, animate, back, historyChange, null, element);
                            }.bind(this));
                        }
                        else {
                            error(data.error);
                        }
                        this.postLink();
                    }.bind(this),
                    onFailure: function() {
                        error('Connection Failure');
                    },
                    onTimeout: function() {
                        error('Timeout');
                    }
                }).send();
            }.bind(this), true);
        },
        register: function() {
            this.linktypes.login.apply(this, arguments);
        },
        screen: function(t, animate, back, historyChange, fetchId, fetchUrl, direct) {
            animate = animate || animate == undefined || false;
            var href, element;
            if (typeOf(t) == 'element') {
                href = t.get('data-href');
                element = t.get('data-element');
                if (!fetchUrl && t.get('data-appshed') != 'appshed') {
                    var screen = t.getParent('.screen');
                    if (screen) {
                        fetchUrl = screen.get('data-fetch-url');
                        direct = screen.get('data-fetch-direct') == 'direct';
                    }
                }
            }
            else {
                href = t;
            }
            appbuilder.app.debug('navigator', 'link', 'screen', href);
            this.phone.fetch('screen', href, function(html) {
                this.showScreen(html, href, animate, back, historyChange, null, element);
            }.bind(this), fetchId, fetchUrl, direct);
            this.phone.history.currentScreenId = href;

        },
        back: function(t) {
            var href = typeOf(t) == 'element' ? t.get('data-href') : t;
            appbuilder.app.debug('navigator', 'link', 'back', href);
            this.phone.history.popHistory(null, href);
        },
        web: function(t, typeX) {
            var type = typeOf(t) == 'element' ? t.get('data-weblinktype') : typeX;
            if (this.weblinks.blackberry.apply(this, arguments)) {
                //ok
            }
            else if (type == 'external') {
                if (window.navigator.standalone && !(typeOf(t) == 'element' ? t.get('data-href') : t).test(/^mailto/)) {
                    this.weblinks.confirm.apply(this, arguments);
                }
                else {
                    this.weblinks.external.apply(this, arguments);
                }
            }
            else if (type == 'iframe') {
                this.weblinks.iframe.apply(this, arguments);
            }
            else if (type == 'confirm') {
                this.weblinks.confirm.apply(this, arguments);
            }
            else {
                if (appbuilder.app.isPhoneGap) {
                    this.weblinks.inappbrowser.apply(this, arguments)
                }
                else if (window.navigator.standalone) {
                    this.weblinks.confirm.apply(this, arguments);
                }
                else if (this.phone.options.fullscreen) {
                    this.weblinks.external.apply(this, arguments);
                }
                else {
                    this.weblinks.iframe.apply(this, arguments);
                }
            }
        },
        file: function(t, animate, back, historyChange) {
            var href = typeOf(t) == 'element' ? t.get('data-href') : t;
            appbuilder.app.debug('navigator', 'link', 'file', href);
            var slash = href.lastIndexOf('/');
            if (slash >= 0)
                slash++;
            else
                slash = 0;
            var dot = href.lastIndexOf('.'),
                    filename = typeOf(t) == 'element' ? t.get('data-filename') : href.substring(slash, dot),
                    type = dot >= 0 ? href.substr(dot + 1).toUpperCase() : '';

            if (href.indexOf('http') !== 0) {
                var strPath = String(window.location);
                var path = strPath.substr(0, strPath.lastIndexOf("/"));
                href = path + '/' + href;
            }
            if (type == 'PDF' && appbuilder.app.isPhoneGap) {
                if(Browser.Platform.android){
                   if(typeof window.openPDF == 'function'){
                       window.openPDF(href);
                   }else{
                       console.log('Missing android pdf plugin');
                   }
                }else{
                    console.log('you have installed  "cordova plugin add org.apache.cordova.inappbrowser" ? ');
                    window.open(href, '_blank', 'location=yes');
                }
                this.postLink();
            }
            else if (type == 'PDF' && (window.navigator.standalone || appbuilder.app.isPhoneGap)) {
                this.weblinks.confirm.apply(this, [href])
            }
            else {
                this.showScreen(appbuilder.app.createScreen('screen' + href, 'file', filename, '', true, false, null, null, 'file', true), href, animate, back, historyChange, function(screen) {
                    var items = screen.getElement('.items .items-inner');
                    if (type == 'PDF' && (this.pdfobject = new PDFObject({
                        url: href,
                        pdfOpenParams: {
                            toolbar: 0,
                            statusbar: 0,
                            messages: 0,
                            navpanes: 0,
                            view: "FitV"
                        }
                    }).embed(items))) {
                    }
                    else if (type == 'PDF') {
                        this.phone.history.popHistory();
                        if (!window.open(href, '_blank')) {
                            window.location = href;
                        }
                    }
                    else {
                        items.set('html', '<div class="title">' + filename + '</div>' +
                                '<a href="' + href + '>Download</a>'
                                );
                    }

                    if (type == 'MP4' || type == 'MOV' || type == 'AVI' || type == 'M4V') {
                        items.set('html', items.get('html') + '<video src="' + href + '" controls="controls">' +
                                '<embed data="' + href + '" width="320" height="480" autoplay="false" controller="true"></embed>' +
                                '</video>');
                    } else if (type == 'MP3') {
                        items.set('html', items.get('html') + '<audio src="' + href + '" controls="controls">' +
                                '<embed data="' + href + '" width="320" height="480" autoplay="false" controller="true"></embed>' +
                                '</audio>');
                    }
                }.bind(this));
            }
        },
        sound: function(t) {
            var href = typeOf(t) == 'element' ? t.get('data-href') : t;
            appbuilder.app.debug('navigator', 'link', 'sound', href);
            var dot = href.lastIndexOf('.'),
                    type = dot >= 0 ? href.substr(dot + 1).toUpperCase() : '';
            if (appbuilder.app.sound.support && appbuilder.app.sound.support[type.toLowerCase()]) {
                appbuilder.app.sound.play(href);
                this.postLink();
            }
        },
        youtube: function(t, animate, back, historyChange) {
            var href = typeOf(t) == 'element' ? t.get('data-href') : t;
            appbuilder.app.debug('navigator', 'link', 'youtube', href);

            if (this.weblinks.blackberry.apply(this, arguments)) {
                //if this is blackberry device
            } else if (Browser.Platform.android) {
                appbuilder.app.makeConfirm('Video will open as external link', href, null, 'OK').inject(document.id(this));
                this.postLink();
            }
            else {
                var m = href.match(/^(?:https?:\/\/(?:www\.)?youtu\.be\/|https?:\/\/(?:www\.)?youtube\.com\/watch\?.*?v=|https?:\/\/(?:www\.)?youtube\.com\/v\/)(.+?)(?:&|\?|$)/i);
                if (m && m[1]) {
                    this.weblinks.iframe.apply(this, ['http://youtube.com/embed/' + m[1], animate, back, historyChange, 'youtube', href, this.findTitle(t, 'Youtube'),'youtube']);
                    //var content = '<object><param name="movie" value="http://www.youtube.com/v/'+m[1]+'"></param><param name="wmode" value="transparent"></param><embed src="http://www.youtube.com/v/'+m[1]+'" type="application/x-shockwave-flash" wmode="transparent"></embed></object>';
                    //this.showScreen(appbuilder.app.createScreen('screenyoutube' + m[1], 'youtube', this.findTitle(t, 'Youtube'), content, true, false), 'youtube' + m[1], false, back, historyChange);
                }
            }
        },
        vimeo: function(t, animate, back, historyChange) {
            var href = typeOf(t) == 'element' ? t.get('data-href') : t;
            appbuilder.app.debug('navigator', 'link', 'vimeo', href);
            var m = href.match(/^https?:\/\/(?:www\.)?vimeo\.com\/(?:couchmode\/)?(?:channels\/.+?(?:\/|#))?(?:.+?\/)?(.+?)(?:&|\?|$)/i);

            if (m && m[1]) {

                var url = 'http://player.vimeo.com/video/' + m[1] + '?title=0&byline=0&portrait=0';
                if (this.weblinks.blackberry.apply(this, arguments)) {
                    //ok
                } else {
                    this.weblinks.iframe.apply(this, [url, animate, back, historyChange, 'vimeo', href, this.findTitle(t, 'Vimeo'),'vimeo']);
                }
            }
        },
        twitter: function(t, animate, back, historyChange) {
            var href = typeOf(t) == 'element' ? t.get('data-href') : t,
                    screenname = href.replace('@', '');
            appbuilder.app.debug('navigator', 'link', 'twitter', href);
            appbuilder.app.loadScript('http://platform.twitter.com/widgets.js', function() {
                this.showScreen(appbuilder.app.createScreen('screen' + href, 'twitter', /*'@' + href*/' ', '', true, false, null, null, 'list twitter'), href, animate, back, historyChange, function(screen) {
                    var items = screen.getElement('.items');
                    (function(){
                       var size = items.getSize();
                        twttr.widgets.createTimeline('344845112704135168', items, function() {
                        }, {
                            width: size.x,
                            height: size.y,
                            screenName: screenname
                        })
                        console.log(size.y);
                        // delay to get right size of .items
                    }).delay(500);
                });
            }.bind(this), function() {
                appbuilder.app.alert('Twitter is currently unavailable', this);
                this.postLink();
            }.bind(this));
        },
        facebook: function(t, animate, back, historyChange) {
            var href = typeOf(t) == 'element' ? t.get('data-href') : t;
            appbuilder.app.debug('navigator', 'link', 'facebook', href);

            var face = 'http://www.facebook.com/plugins/likebox.php?href=' + encodeURIComponent(href) + '&width=100%&height=100%&colorscheme=light&show_faces=true&border_color=black&stream=true&header=false';//&appId=156047001092869';
            if (this.weblinks.blackberry.apply(face)) {
                //ok
            } else {
                this.showScreen(appbuilder.app.createScreen('screen' + href, 'facebook', /*href.substring(href.lastIndexOf('/') + 1)*/' ', '', true, false, null, null, 'list facebook'), href, animate, back, historyChange, function(screen) {
                    var i = screen.getElement('.items'), size = i.getSize(), width = size.x, height = size.y;
                    var face = 'http://www.facebook.com/plugins/likebox.php?href=' + encodeURIComponent(href) + '&width=' + width + '&height=' + height + '&colorscheme=light&show_faces=true&border_color=black&stream=true&header=false';//&appId=156047001092869';
                    //var content = '<iframe src="' + face + '" allowTransparency="true" scrolling="no" frameborder="0" style="border:none; overflow:hidden; width:' + width + 'px; height:' + height + 'px;"  ></iframe>';
                    var content = '<iframe src="' + face + '" allowTransparency="true" scrolling="no" frameborder="0" style="border:none; overflow:hidden; width:100%; height:100%;"  ></iframe>';
                    i.set('html', content);
                });
            }
        },
        remote: function(t, animate, back, historyChange) {
            var href, direct;
            if (typeOf(t) == 'element') {
                href = t.get('data-href');
                direct = !!t.get('data-direct');
            }
            else {
                href = t;
            }
            appbuilder.app.debug('navigator', 'link', 'remote', href);
            this.phone.loader.showLoading();
            this.jsAPI.showRemoteScreen(href, direct, animate, back, historyChange);
        },
        refresh: function(t) {
            var screen;
            if (typeOf(t) == 'element' && (screen = t.getParent('.screen'))) {
                var fetchUrl = screen.get('data-fetch-url'),
                        id = screen.get('id').replace('screen', '');
                this.phone.loader.showLoading();
                this.phone.fetchLive(screen.get('data-fetch-direct') || 'remote', fetchUrl, function(mainScreenId, fetchObj) {
                    this.phone.loader.hideLoading();
                    if (fetchObj && fetchObj.screen[id]) {
                        this.showScreen(fetchObj.screen[id], id, false, false, true);
                    }
                    else {
                        appbuilder.app.makeConfirm('This screen is currently unavailable', null, null, null, false).inject(document.id(this));
                        this.postLink();
                    }
                }.bind(this), null, true);
                return;
            }
            this.postLink();
        },
        jscode: function(t) {
            var href = typeOf(t) == 'element' ? t.get('data-href') : t;
            appbuilder.app.debug('navigator', 'link', 'jscode', href);
            if (this.screenObj && this.screenObj.javascripts[href]) {
                this.jsAPI.js(this.screenObj.javascripts[href], this.app);
            }
            this.postLink();
        },
        bbm: function() {
            appbuilder.app.debug('navigator', 'link', 'bbm');
            appbuilder.app.BBM.shareApp(this.postLink.bind(this), this);
        },
        iot: function(t,value) {
            var href = typeOf(t) == 'element' ? t.get('data-href') : t;
            var value = typeOf(t) == 'element' ? t.get('data-value') : value;
            this.jsAPI.iot(href,value);
            this.postLink();
        }
    },
    weblinks: {
        iframe: function(t, animate, back, historyChange, type, typeurl, title, cssClass ) {
            var href = typeOf(t) == 'element' ? t.get('data-href') : t;
            appbuilder.app.debug('navigator', 'web', 'iframe', href);
            this.checkVariables(href, function(href) {
                var content = '<iframe src="' + href + '"></iframe>';
                this.showScreen(appbuilder.app.createScreen('screen' + (typeurl || href), type || 'web', title || this.findTitle(t, 'Website'), content, true, false,null, null,'web '+cssClass), typeurl || href, animate, back, historyChange);
            }.bind(this));
        },
        blackberry: function(t) {
            var href = typeOf(t) == 'element' ? t.get('data-href') : t;

            if (window.blackberry && window.blackberry.invoke && Browser.Platform.blackberry && (Browser.version < 7.2)) {
                this.checkVariables(href, function(href) {
                    blackberry.invoke.invoke(
                            blackberry.invoke.APP_BROWSER,
                            new blackberry.invoke.BrowserArguments(href)
                            );
                    this.postLink();
                }.bind(this));
                return true;
            }
            else {
                return false;
            }


        },
        external: function(t) {
            var href = typeOf(t) == 'element' ? t.get('data-href') : t;
            appbuilder.app.debug('navigator', 'web', 'external', href);
            this.checkVariables(href, function(href) {
                this.postLink();
                if (href.test(/^mailto/)) {
                    href = href.replace(/\+/g, '%20');
                }
                if (appbuilder.app.isPhoneGap) {
                    window.open(href, '_system');
                }
                else {
                    if (href.test(/^mailto/)) {
                        window.location = href;
                    }
                    else {

                        var a = document.createElement('a');
                        a.setAttribute("href", href);
                        a.setAttribute("target", "_blank");

                        var dispatch = document.createEvent("HTMLEvents");
                        dispatch.initEvent("click", true, true);
                        a.dispatchEvent(dispatch);

                        ///window.open(href, '_blank');
                    }
                }
            }.bind(this));
        },
        inappbrowser: function(t) {
            var href = typeOf(t) == 'element' ? t.get('data-href') : t;
            appbuilder.app.debug('navigator', 'web', 'inappbrowser', href);
            if (Browser.Platform.android) {
                if (window.AndroidPdfPlugin) {
                    if (href.lastIndexOf('.pdf') > 0) {
                        if (href.indexOf('http://') === 0 || href.indexOf('https://') === 0) {
                            AndroidPdfPlugin.openOnlinePDF(href);
                        } else {
                            AndroidPdfPlugin.openPDF(href);
                        }
                        return;
                    }
                } else {
                    console.log('need install AndroidPdfPlugin to show pdf files on android platform');
                }
            }

            this.checkVariables(href, function(href) {
                this.postLink();
                window.open(href, '_blank');
            }.bind(this));
        },
        confirm: function(t) {
            var href, mess, okbtn;
            appbuilder.app.debug('navigator', 'web', 'confirm');
            if (typeOf(t) == 'element') {
                href = t.get('data-href');
                mess = t.get('data-confirm-message') || 'This link will open in ' + (Browser.Platform.ios ? 'Safari' : 'your Browser');
                okbtn = t.get('data-okbtn') || 'OK';
            }
            else if (typeOf(t) == 'object') {
                href = t.href;
                mess = t.message || 'This link will open in ' + (Browser.Platform.ios ? 'Safari' : 'your Browser');
                okbtn = t.okbtn || 'OK';
            }
            else {
                href = t;
                mess = 'This link will open in ' + (Browser.Platform.ios ? 'Safari' : 'your Browser');
            }
            this.checkVariables(href, function(href) {
                appbuilder.app.makeConfirm(mess, href, null, okbtn).inject(document.id(this));
                this.postLink();
            }.bind(this));
        }
    },
    getTitle: function(el, defaultTitle) {
        var title;
        if (typeOf(el) == 'element') {
            var tEl = el.getElement('.title');
            if (tEl) {
                title = tEl.get('text');
            }
            else {
                title = el.get('text');
            }
        }
        return title || defaultTitle || 'Website';
    },
    showApp: function(html, animate, after, id, remote) {
        if (html) {
            appbuilder.app.debug('navigator', 'showApp', html);
            if (html.html) {
                this.appObj = html;
            }

            var thisEl = document.id(this);
            var s = thisEl.getElement('.screen');
            if (s) {
                s.fireEvent('destroy');
            }
            if (this.app) {
                this.app.fireEvent('destroy');
            };

            if(window.appbuilder.events){
                window.appbuilder.events.iot.close();
            };

            if( html.iotConfig  && html.pinMap && window.appbuilder.events){
                window.appbuilder.events.iot.close();
                window.appbuilder.events.iot.init(html.iotConfig,html.pinMap);
            };

            this.events.initAppEvents(html.events);
            var t = this;
            var showapphtml = function() {
                thisEl.set('html', t.fixIEStyles((html.html || html), 'app'));
                this.app = document.id(this).getElement('.app');
                if (this.app) {
                    if (this.app.get('data-disable-masking')) {
                        document.id(this.phone).removeClass('masking');
                        this.phone.options.currentMasking = false;
                    }
                    else if (this.phone.options.masking) {
                        document.id(this.phone).addClass('masking');
                        this.phone.options.currentMasking = true;
                    }

                    //Auto upgrade/downgrade
                    this.phone.options.currentIOS7 = !this.app.get('data-disable-ios7');
                    if (this.phone.options.currentIOS7) {
                        if (this.phone.options.style == 'iphone5') {
                            this.phone.setStyle('iphone5s');
                        } else if (this.phone.options.style == 'ipad') {
                            this.phone.setStyle('ipadair');
                        }
                    }
                    else {
                        if (this.phone.options.style == 'iphone5s') {
                            this.phone.setStyle('iphone5');
                        } else if (this.phone.options.style == 'ipadair') {
                            this.phone.setStyle('ipad');
                        }
                    }

                    this.phone.history.currentAppUrl = this.app.get('data-fetch-url');
                    if (this.phone.options.app != 1 && id != this.phone.options.app) {
                        appbuilder.app.makeElement('close-btn', {
                            'data-linktype': 'app',
                            'data-href': this.phone.options.app
                        }).inject(this.app);
                    }
                    var appNavigatorInner = this.app.getElement('.app-navigator-inner'),
                            sides = appNavigatorInner.getElements('.app-navigator-inner-sides'),
                            innerA = sides[0],
                            innerB = sides[1],
                            back = false,
                            running = false,
                            chain = [],
                            swapAB = function() {
                                innerB.addClass('a');
                                innerB.removeClass('b');

                                innerA.addClass('b');
                                innerA.removeClass('a');

                                var tmp = innerB;
                                innerB = innerA;
                                innerA = tmp;
                            }.bind(this),
                            morpher = new Fx.Morph(appNavigatorInner, {
                                unit: '%',
                                onComplete: function() {
                                    if (!back) {
                                        swapAB();
                                        appNavigatorInner.setStyle('left', 0);
                                    }
                                    var s = innerB.getElement('.screen');
                                    if (s) {
                                        s.fireEvent('destroy');
                                    }
                                    innerB.set('html', '');
                                    appNavigatorInner.removeClass('ani');
                                    running = false;
                                    this.postLink();
                                    var n = chain.shift();
                                    if (n) {
                                        n();
                                    }
                                }.bind(this)
                            }),
                    changeScreens = function() {
                        if (back) {
                            appNavigatorInner.setStyle('left', '-100%');
                            swapAB();
                            morpher.start({
                                left: ['-100', 0]
                            });
                        }
                        else {
                            morpher.start({
                                left: [0, '-100']
                            });
                        }
                    };
                    this.showScreenHTML = function(html, animate, backParam, setup) {
                        if (running !== false && Date.now() - running < 5000) {
                            chain.push(function() {
                                this.showScreenHTML(html, backParam, animate, backParam, setup);
                            }.bind(this));
                            return;
                        }
                        running = Date.now();

                        if (animate) {
                            appNavigatorInner.addClass('ani');
                            innerB.set('html', t.fixIEStyles((html.html || html), 'b'));
                            if (setup) {
                                setup(innerB);
                            }
                            back = backParam;
                            changeScreens();
                        }
                        else {
                            var s = innerA.getElement('.screen');
                            if (s) {
                                s.fireEvent('destroy');
                            }
                            innerA.set('html', t.fixIEStyles((html.html || html), 'a'));
                            if (setup) {
                                setup(innerA);
                            }
                            running = false;
                            this.postLink();
                        }
                    };

                    innerA.addClass('a');
                    innerB.addClass('b');

                    if (after) {
                        after();
                        this.postLink();
                    }
                    else {
                        this.enabled = true;
                        this.click(null, this.app.getElement('.tab'));
                        this.postLink();
                    }

                    appNavigatorInner.ha
                    this.checkAds.delay(500, this);

                    this.tabBar = this.app.getElement('.tab-bar');
                    if (this.tabBar) {
                        this.app.addEvent('click:relay(.screen .header)', function(e) {
                            this.tabBar.toggleClass('bbShow');
                        }.bind(this));
                    }


                    this.jsAPI.js( html.javascript, this.app);

                    this.phone.fireEvent('app', [id, this.app, remote]);
                }
                else {
                    this.app = false;
                }
            }.bind(this)

            if (animate && this.phone.options.splash && html.splashhtml) {
                thisEl.set('html', this.fixIEStyles(html.splashhtml, 'app'));
                thisEl.getElement('.splash').addEvent('click', function() {
                    clearTimeout(delay);
                    showapphtml();
                });
                var delay = showapphtml.delay(3000);
            }
            else {
                showapphtml();
            }
        }
        else {
            appbuilder.app.debug('navigator', 'showApp', 'no');
            appbuilder.app.alert('This app is currently unavailable', this);
            this.postLink();
        }
    },
    showScreen: function(html, id, animate, back, historyChange, inserted, element) {
        if (html.secured && this.appObj && (id != this.appObj.secure.login && id != this.appObj.secure.register)) {
            if (window.localStorage['login-' + id]) {
                var timeDiff = parseInt(window.localStorage['login-' + id]) - parseInt(new Date().getTime() / 1000);
                if (timeDiff < 0) {
                    this.phone.fetch('screen', this.appObj.secure.login, function(html) {
                        window.localStorage['loginto'] = id;
                        this.showScreen(html, this.appObj.secure.login, animate, back, historyChange, null, element);
                    }.bind(this));
                    return;
                }
            }
            else {
                if (this.appObj.secure.login) {
                    this.phone.fetch('screen', this.appObj.secure.login, function(html) {
                        window.localStorage['loginto'] = id;
                        this.showScreen(html, this.appObj.secure.login, animate, back, historyChange, null, element);
                    }.bind(this));
                    return;
                }
            }
        }
        else {
            this.appObj.loginLink = false;
        }

        if (html && this.app) {
            appbuilder.app.debug('navigator', 'showScreen', html);
            this.showScreenHTML(html, animate, back, function(el) {


                this.events.initScreenEvents(html.events);
                if (html.html) {
                    this.screenObj = html;
                }
                if (this.afterSearch) {
                    this.highlightSearchWords(el);
                }
                var screen = this.screen = el.getElement('.screen');
                if (screen) {
                    var showTabs = screen.get('data-tabs');
                    this.app.removeClass('hide-tabs');
                    this.app.removeClass('float-tabs');
                    if (showTabs == 'hide') {
                        this.app.addClass('hide-tabs');
                    }
                    else if (showTabs == 'float') {
                        this.app.addClass('float-tabs');
                    }

                    var statusType = screen.get('data-status');
                    var phoneEl = document.id(this.phone);
                    if (statusType == 'float') {
                        phoneEl.addClass('float-status');
                        phoneEl.removeClass('status-black');
                    }
                    else {
                        phoneEl.removeClass('float-status');
                        if (statusType == 'black') {
                            phoneEl.addClass('status-black');
                        }
                        else {
                            phoneEl.removeClass('status-black');
                        }
                    }

                    var selectedTab = screen.get('data-tab');
                    if (selectedTab) {
                        var newTab = this.app.getElement('#tab' + selectedTab);
                        if (newTab) {
                            this.app.getElements('.tab.selected').removeClass('selected');
                            newTab.addClass('selected');
                            this.phone.fireEvent('tab', [selectedTab, newTab]);
                        }
                    }

                    var headerEl = screen.getElement('.header');
                    var backEl = screen.getElement('.back[data-linktype=back]');
                    var titleEl = screen.getElement('.header .title');
                    var titleSpan = screen.getElement('.header .title span');
                    var titleText = titleSpan ? titleSpan.get('text') : '';
                    var backWidth = 0;
                    if (backEl) {
                        var href = backEl.get('data-href');
                        if (href && !historyChange) {
                            this.phone.history.amendHistory({
                                screen: href,
                                app: this.phone.history.currentApp
                            });
                        }

                        if (!this.phone.history.hasHistory(historyChange) && !href) {
                            backEl.destroy();
                            backEl = false;
                        }
                        else {
                            headerEl.addClass('has-back');

                            var t = backEl.get('text');
                            backEl.set('text', '');
                            appbuilder.app.makeElement('back-left', {
                                'html': '<svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 12 30" overflow="visible">'
                                        + '<polyline points="12,0 0,15 12,30"/>'
                                        + '<polygon points="12,1 1,15 12,29"/>'
                                        + '</svg>'
                            }).inject(backEl);

                            var backCenter = appbuilder.app.makeElement('back-center', {
                                text: t
                            }).inject(backEl);

                            var backRight = appbuilder.app.makeElement('back-right').inject(backEl);
                            if (Browser.Platform.android) {
                                backCenter.addClass('android');
                                backRight.addClass('android');
                            }
                            else {
                                backEl.addClass('iphone');
                            }
                            backWidth = backEl.getSize().x;
                        }
                    }

                    if (titleSpan) {
                        var resizeTitle = function() {
                            var titleWidth = titleSpan.getDimensions().x;
                            var headerWidth = headerEl.getDimensions().x;
                            if (titleWidth + (backWidth * 2) <= headerWidth) {
                                titleEl.removeClass('left');
                            }
                            else {
                                titleEl.addClass('left');
                            }
                        }
                        resizeTitle();
                        this.phone.addEvent('resize', resizeTitle);
                        screen.addEvent('destroy', function() {
                            this.phone.removeEvent('resize', resizeTitle);
                        }.bind(this));
                    }

                    if (!historyChange) {
                        var type = screen.get('data-type');
                        if (type) {
                            this.phone.history.pushHistory({
                                screen: id,
                                linktype: type,
                                app: this.phone.history.currentApp,
                                appUrl: this.phone.history.currentAppUrl,
                                title: titleText,
                                fetchId: screen.get('data-fetch-id'),
                                fetchUrl: screen.get('data-fetch-url'),
                                direct: !!screen.get('data-fetch-direct')
                            });
                        }
                        else {
                            this.phone.history.pushHistory({
                                screen: id,
                                app: this.phone.history.currentApp,
                                appUrl: this.phone.history.currentAppUrl,
                                title: titleText,
                                fetchId: screen.get('data-fetch-id'),
                                fetchUrl: screen.get('data-fetch-url'),
                                direct: !!screen.get('data-fetch-direct')
                            });
                        }
                    }

                    //Run screen setup function, setups for individual types
                    Object.each(appbuilder.app.Navigator.screenSetups, function(func, name) {
                        if (screen.hasClass(name)) {
                            func.apply(this, [screen, html]);
                        }
                    }, this);

                    //setup scrolling
                    if (!this.phone.options.fixed) {
                        var items = screen.getElement('.items');
                        if (items && items.hasClass('scrolling')) {
                            var scroll = items.retrieve('scroll');
                            if (!scroll) {
                                if (screen.hasClass('gallery')) {
                                    scroll = new appbuilder.app.scroll.gallery(items);
                                }
                                else if (screen.hasClass('appsscreen')) {
                                    scroll = new appbuilder.app.scroll.appsscreen(items);
                                }
                                else {
                                    scroll = new appbuilder.app.scroll.iphone(items);
                                }
                                items.store('scroll', scroll);
                            }

                            //wait until we are definitely in the screen
                            (function() {
                                scroll.refresh();
                            }).delay(0);

                            if (element) {
                                var e = screen.getElement(element);
                                if (e) {
                                    scroll.scrollToElement(e, 0);
                                }
                            }

                            screen.getElements('img').addEvent('load', function() {
                                scroll.refresh();
                            });

                            var resizeE = function() {
                                scroll.refresh();
                            };
                            this.phone.addEvent('resize', resizeE);
                            screen.addEvent('destroy', function() {
                                this.phone.removeEvent('resize', resizeE);
                            }.bind(this));

                            if ((Browser.Platform.ipodIos7 || Browser.Platform.iphoneIos7) && !window.navigator.standalone) {
                                var screenorientationchange = function() {
                                    if (window.orientation == 0) {
                                        scroll.enable()
                                        scroll.refresh();
                                    } else {
                                        scroll.disable();
                                    }
                                }
                                window.addEventListener("orientationchange", screenorientationchange, false);
                                screenorientationchange();
                                screen.addEvent('destroy', function() {
                                    window.removeEvent('resize', screenorientationchange);
                                }.bind(this));
                            }

                        }
                    }

                    if (inserted) {
                        inserted(screen);
                    }

                    if(parseInt(id)!==1){
                      this.moveAdsInToScreen(screen);
                    }
                    this.phone.fireEvent('screen', [id, screen, !!screen.get('data-fetch-url')]);
                }
            }.bind(this));

        }
        else {
            appbuilder.app.debug('navigator', 'showScreen', 'no');
            appbuilder.app.alert('This screen is currently unavailable', this);
            this.postLink();
        }
    },
    /**
     * Finds a title from an element
     * Used to make titles for youtube/vimeo/web pages
     **/
    findTitle: function(el, defaultTitle) {
        var title;
        if (typeOf(el) == 'element') {
            var tEl = el.getElement('.title');
            if (tEl) {
                title = tEl.get('text');
            }
            else {
                title = el.get('text');
            }
        }
        return title || defaultTitle || 'Website';
    },
    loadVar: function(varName) {
        var obj = this.phone.temporaryStorage.fetch('inputs', varName);
        if (!obj && this.phone.storage) {
            obj = this.phone.storage.fetch('inputs', varName);
        }
        appbuilder.app.debug('navigator', 'loadVar', varName, obj);
        return obj;
    },

    hasAds: function(){
       return  this.app.getElement('.app-navigator').hasClass('ads');
    },
    saveVar: function(varName, value, method, type) {
        var obj = {
            name: varName,
            value: value,
            type: type || 'text',
            method: method || 'get'
        };
        appbuilder.app.debug('navigator', 'saveVar', varName, obj);
        this.phone.temporaryStorage.store('inputs', varName, obj);
        if (this.phone.storage) {
            this.phone.storage.store('inputs', varName, obj);
        }
        this.phone.fireEvent('varChange', [varName, value]);
    },
    checkVariables: function(href, cb, post) {
        appbuilder.app.decoy();
        var match, pattern = /\{([\w\._]+?)\}/g,
                count = 1,
                postVars = [],
                after = function() {
                    if (--count == 0) {
                        cb(href2, postVars);
                    }
                },
                href2 = href;
        while ((match = pattern.exec(href)) != null) {
            count++;
            (function(match) {
                if (match[1] == 'device.longitude') {
                    match[1] = 'device.location.longitude';
                }

                if (match[1] == 'device.latitude') {
                    match[1] = 'device.location.latitude';
                }

                if (match[1] == 'device.accuracy') {
                    match[1] = 'device.location.accuracy';
                }

                if (match[1] == 'device.udid') {
                    href2 = href2.replace(match[0], encodeURIComponent(this.phone.getUniqueId()));
                    after();
                }
                else if (match[1].indexOf('device.location') === 0) {
                    var key = match[1].replace('device.location.', '');
                    appbuilder.map.getPosition(function(p) {
                        if (p && p.coords[key]) {
                            href2 = href2.replace(match[0], encodeURIComponent(p.coords[key]));
                        }
                        else {
                            href2 = href2.replace(match[0], '');
                        }
                        after();
                    });
                }
                else {
                    var inp = this.loadVar(match[1]);
                    if (inp) {
                        if (inp.method == 'post') {
                            if (post) {
                                postVars.push(inp);
                            }
                            href2 = href2.replace(match[0], '');
                        }
                        else {
                            if(inp.type == 'date'){
                                href2 = href2.replace(match[0], encodeURIComponent(new Date(inp.value).toLocaleDateString()));
                            }else{
                                href2 = href2.replace(match[0], encodeURIComponent(inp.value));
                            }
                        }
                        console.log(href2);
                    }
                    else {
                        href2 = href2.replace(match[0], '');
                    }
                    after();
                }
            }).call(this, match);
        }
        after();
    },
    checkAds: function() {

        if( !this.hasAds() || (document.getElement('ins')!==null && document.getElement('ins').getParent('.ad-holder')!==null)){
            return;
        }

        var adsItems = document.getElements('.ad-holder');
        if(adsItems.length==0){
            adsItems.push(new Element('div',{'class':'ad-holder'}).inject(document.body));
        }

        adsItems.each(function(holder) {

            var gads = document.id('gads')
            ,gad = gads && gads.getElement('ins')
            ,timeout
            ,showAd = gads ? function(obj) {
                        if (obj.gads) {
                            gad.inject(holder);
                            (window.adsbygoogle = window.adsbygoogle || []).push({});
                        }
                        else {
                            gad.inject(gads);
                            holder.set('html', obj.ad);
                            timeout = setTimeout(getAd, 60000);
                        }
                    } : function(obj) {
                holder.set('html', obj.ad);
                timeout = setTimeout(getAd, 60000);
            }
            ,getAd = function () {
                new (appbuilder.app.hasCORS ? Request.JSON : Request.JSONP)({
                    url: 'https://ads2.appshed.com/',
                    data: {
                        device: this.phone.getUniqueId(),
                        app: this.phone.history.currentApp,
                        editor: this.phone.options.request.data.editor
                    },
                    noCache: true,
                    onSuccess: showAd,
                    onFailure: function (xhr) {
                        appbuilder.app.debug('navigator', 'ads', 'fail', xhr);
                    },
                    method: 'get'
                }).send();
            }.bind(this);

            getAd();
            this.app.addEvent('destroy', function() {
                clearTimeout(timeout);
            });
        }, this);
    },

    moveAdsInToScreen: function(screen){
        if(!this.hasAds()) return;
        var ads = document.getElement('.ad-holder');
        if(ads){
            if(screen.getElement('.items-inner')){
                ads.inject(screen.getElement('.items-inner'),'top');
            }else if(screen.getElement('.items')){
                ads.inject(screen.getElement('.items'),'top');
            }
            ads.show();
        }
    },
    moveAdsInToBody: function(){
        var ads = document.getElement('.ad-holder');
        if(ads){
            ads.inject(document.getElement('body'));
            ads.hide();
        }
    },


    highlightSearchWords: function(screenEl) {
        this.afterSearch = false;
        var bodyText = screenEl.innerHTML;
        var highlightStartTag = "<span style='color:blue; background-color:yellow;'>";
        var highlightEndTag = "</span>";

        var newText = "";
        var i = -1;
        var lcBodyText = bodyText.toLowerCase();

        while (bodyText.length > 0) {
            i = lcBodyText.indexOf(this.searchword, i + 1);
            if (i < 0) {
                newText += bodyText;
                bodyText = "";
            } else {
                if (bodyText.lastIndexOf(">", i) >= bodyText.lastIndexOf("<", i)) {
                    if (lcBodyText.lastIndexOf("/script>", i) >= lcBodyText.lastIndexOf("<script", i)) {
                        newText += bodyText.substring(0, i) + highlightStartTag + bodyText.substr(i, this.searchword.length) + highlightEndTag;
                        bodyText = bodyText.substr(i + this.searchword.length);
                        lcBodyText = bodyText.toLowerCase();
                        i = -1;
                    }
                }
            }
        }
        screenEl.innerHTML = newText;
    },
    /*
     * fix dynamic updates styles for ie7/8
     * moves all styles into head
     * there is still a problem when the main css is not minified that 31 
     * stylesheet limit is reached
     *
     * defined as empty function when not in ie
     **/
    fixIEStyles: appbuilder.app.isIE ? function(html, type) {
        var headerElement = document.getElementsByTagName('head')[0];
        var pageData = (html.html || html).match(/(<style[\s\S]*?<\/style>)([\s\S]*)/i);
        if (pageData) {
            var style = pageData[1].match(/<style.+>([\s\S]*?)<\/style>/i);
            if (style[1]) {
                var styleElement = document.getElementById('ie-style-' + type);
                if (styleElement) {
                    document.id(styleElement).destroy();
                }
                styleElement = document.createElement('style');
                styleElement.setAttribute('type', 'text/css');
                styleElement.setAttribute('id', 'ie-style-' + type);
                styleElement.styleSheet.cssText = style[1];
                headerElement.appendChild(styleElement);
            }
            return pageData[2];
        }
        return html;
    } : function(html) {
        return html;
    },
    applyTabMask: function() {
        if (this.tabBar) {
            this.tabBar.getElements('.tab .icon').each(function(img) {
                if (!img.get('data-src')) {
                    var src = img.get('src'),
                            mask = 'url(\'' + src + '\') top left / contain no-repeat';
                    img.setStyles({
                        'mask': mask,
                        '-webkit-mask': mask,
                        '-o-mask': mask,
                        '-ms-mask': mask
                    });
                    img.set('data-src', src);
                    //ios doesnt remove the image if i just remove the src attribute - this is 1 transparent pixel
                    img.set('src', 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
                }
            });
        }
    },
    removeTabMask: function() {
        if (this.tabBar) {
            this.tabBar.getElements('.tab .icon').each(function(img) {
                if (img.get('data-src')) {
                    img.setStyles({
                        '-webkit-mask': null,
                        '-o-mask': null,
                        '-ms-mask': null,
                        'mask': null
                    });
                    img.set('src', img.get('data-src'));
                    img.removeProperty('data-src');
                }
            });
        }
    }
}, 'Navigator');
