appbuilder.app.Navigator.screenSetups = appbuilder.app.Navigator.screenSetups || {};
appbuilder.app.Navigator.screenSetups.gallery = function(screen) {
	var resize = screen.retrieve('resize');
	if(!resize) {
		var itemsEl = screen.getElement('.items'),
		items = screen.getElements('.item.gallery'),
		inner = itemsEl.getElement('.items-inner');
		resize = function() {
			appbuilder.app.debug('gallery', 'resize');
			var s = itemsEl.getSize();
			items.setStyles({
				width: s.x + 'px',
				height: s.y + 'px'
			});
			inner.setStyles({
				width: (s.x * items.length) + 'px',
				height: s.y + 'px'
			});
		}
		this.phone.addEvent('resize', resize);
		screen.addEvent('destroy', function() {
			appbuilder.app.debug('gallery', 'destroy');
			this.phone.removeEvent('resize', resize);
		}.bind(this));
	}
	resize();
};
