appbuilder = window.appbuilder || {};
appbuilder.events = appbuilder.events || {};
appbuilder.events.api = appbuilder.events.api || {};

(function (window) {

    var eventer_api = {},
        fakeVals = {},
        lastVals = {},
        apiWatchIds = {};

    eventer_api.addEvent = function (callback, url, path, interval) {
        var key = encodeKey([path, interval, url]);
        if (!apiWatchIds[key]) {
            apiWatchIds[key] = {last: null};
            apiWatchIds[key][url] = true;
        }
        fakeVals[key] = null;
        lastVals[key] = {'new': true, val: null};


        startWatcher(callback, url, path, interval);
    };

    eventer_api.removeEvent = function (callback, url, path, interval) {
        var key = encodeKey([path, interval, url]);
        if (apiWatchIds[key] && apiWatchIds[key][url]) {
            clearTimeout(apiWatchIds[key][url]);
            delete(apiWatchIds[key][url]);
            if (Object.keys(apiWatchIds[key]).length == 0) {
                delete(apiWatchIds[key]);
            }
        }
    };
    eventer_api.setFakeValue = function (url, path, interval, value) {
        var key = encodeKey([path, interval, url]);
        fakeVals[key] = value;
    };


    startWatcher = function (callback, url, path, interval) {
        try {
            var key = encodeKey([path, interval, url]);
            if (apiWatchIds[key][url]) {
                apiWatchIds[key][url] = setTimeout(function () {
                    var successFunc = function (responseText) {
                        if (responseText) {
                            if (!path || path.length == 0) {
                                if (responseText != apiWatchIds[key][last]) {
                                    callback(responseText);
                                }
                                lastVals[key]['val'] = responseText;
                            } else {
                                var pathArr = path.split('.'),
                                    json = JSON.parse(responseText),
                                    ansver = null;
                                for (var i = 0; i < pathArr.length; i++) {
                                    var tmp = ansver ? ansver : json,
                                        tmpKey = pathArr[i];
                                    if (tmp[tmpKey]) {
                                        ansver = tmp[tmpKey];
                                        if (i + 1 == pathArr.length) {
                                            if (lastVals[key]['new'] || (lastVals[key]['val'] != ansver)) {
                                                lastVals[key]['new'] = false;
                                                callback(ansver);
                                            }
                                            lastVals[key]['val'] = ansver;
                                        }
                                    } else {
                                        if (lastVals[key]['new'] || (lastVals[key]['val'] != null)) {
                                            callback();
                                            lastVals[key]['new'] = false;
                                            break;
                                        }
                                        lastVals[key]['val'] = null;
                                    }
                                }

                            }
                        }

                        if (apiWatchIds[key][url]) {
                            startWatcher(callback, url, path, interval);
                        }
                    }


                    if (window.appbuilder.preview) {
                        successFunc(fakeVals[key]);
                    } else {
                        new Request({
                            url: url,
                            onSuccess: successFunc,
                            onFailure: function (err) {
                                console.log(err);
                                if (apiWatchIds[key] && apiWatchIds[key][url]) {
                                    startWatcher(callback, url, path, interval);
                                }
                            }
                        }).send();
                    }
                }, interval);
            }
        } catch (e) {
            console.error(e);
        }
    };

    function encodeKey(arr) {
        return arr.join("|");
    }

    function decodeKey(key) {
        return key.split('|');
    }

    window.appbuilder.events.api = eventer_api;
})(window);
