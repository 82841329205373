appbuilder = window.appbuilder || {};
appbuilder.app = appbuilder.app || {};

appbuilder.app.Events = new Class({
    appEvents: [],
    screenEvents: [],
    initialize: function (jsApi) {
        appbuilder.app.ActionHandler.init(jsApi);
        window.appbuilder.EventsList = {
            'screen': this.screenEvents,
            'app': this.appEvents
        }
    },
    appEvents: [],
    screenEvents: [],

    compare: function (actual, needed, method, range) {

        if (method == 'equal') { // =
            if (actual == needed || ( ((needed - range) <= actual) && (actual <= (needed + range)))) {
                return true;
            }
        }
        else if (method == 'notequal') { //!=
            if ((range && ((actual < (needed - range)) || ((needed + range) < actual ))) ||  (!range && (needed != actual))) {
                return true;
            }
        }
        else if (method == 'lessthan') { //<
            if (actual < needed) {
                return true;
            }
        }
        else if (method == 'greaterthan') { //>
            if (actual > needed) {
                return true;
            }
        }
        else if (method == 'lessthanorequalto') { //<=
            if (actual <= needed) {
                return true;
            }
        }
        else if (method == 'greaterthanorequalto') {
            if (actual >= needed) {
                return true;
            }
        }
        return false;
    },


    addEvent: function (event) {

        var cb = function (value) {

            if((event.type == 'location' || event.type == 'compass') && value) {
                if (event.callback) {
                    event.callback();
                } else {
                    appbuilder.app.ActionHandler.fireAction(event.action);
                }
            } else if (value && event.type == 'url' || event.type == 'api') {
                if (this.compare(value, event.value, event.method, event.range)) {
                    if (event.callback) {
                        event.callback();
                    } else {
                        appbuilder.app.ActionHandler.fireAction(event.action);
                    }
                }
            } else if (event.type == 'iot') {
                if (this.compare(value, event.value, event.method, event.range)) {
                    if (event.callback) {
                        event.callback();
                    } else {
                        appbuilder.app.ActionHandler.fireAction(event.action);
                    }
                }
            } else if (event.type == 'shake') {
                if (event.callback) {
                    event.callback();
                } else {
                    appbuilder.app.ActionHandler.fireAction(event.action);
                }
            }
        }.bind(this);

        if (event.type == 'url' || event.type == 'api') {
            appbuilder.events.api.addEvent(cb, event.url, event.variable, event.period);
        }
        else if (event.type == 'iot') {
            appbuilder.events.iot.addEvent(cb, event.variable, event.value, event.range);
        }
        else if (event.type == 'compass') {
            appbuilder.events.device.addCompassEvent(cb, event.value, event.range);
        }
        else if (event.type == 'shake') {
            appbuilder.events.device.addShakeEvent(cb);
        }
        else if (event.type == 'location') {
            appbuilder.events.device.addLocationEvent(cb, event.lat, event.lng ,event.method,event.value,event.range);
        }
        return {
            'event': event,
            'cb': cb
        };
    },

    removeEvent: function (eventDescriptor) {

        console.log('remove event', eventDescriptor);

        if (eventDescriptor.event.type == 'url' || eventDescriptor.event.type == 'api') {
            appbuilder.events.api.removeEvent(eventDescriptor.cb, eventDescriptor.event.url, eventDescriptor.event.variable, eventDescriptor.event.period);
        }
        else if (eventDescriptor.event.type == 'iot') {
            appbuilder.events.iot.removeEvent(eventDescriptor.cb, eventDescriptor.event.variable, eventDescriptor.event.value, eventDescriptor.event.range);
        }
        else if (eventDescriptor.event.type == 'compass') {
            appbuilder.events.device.removeCompassEvent(eventDescriptor.cb, eventDescriptor.event.value, eventDescriptor.event.range);
        }
        else if (eventDescriptor.event.type == 'shake') {
            appbuilder.events.device.removeShakeEvent(eventDescriptor.cb);
        }
        else if (eventDescriptor.event.type == 'location') {
            appbuilder.events.device.removeLocationEvent(eventDescriptor.cb, eventDescriptor.event.lat, eventDescriptor.event.lng, eventDescriptor.event.method, eventDescriptor.event.value, eventDescriptor.event.range);
        }
    },

    initAppEvents: function (events) {
        if (appbuilder.events && (appbuilder.events.device || appbuilder.events.api || appbuilder.events.iot) && events) {
            this.cleanEvents('app');
            console.log('init app  events', events);
            //this.appEvents = [];
            events.each(function (event) {
                this.appEvents.push(this.addEvent(event));
            }.bind(this));
        }
    },

    initScreenEvents: function (events) {
        this.cleanEvents('screen');
        if (appbuilder.events &&  (appbuilder.events.device || appbuilder.events.api || appbuilder.events.iot) && events) {
            console.log('init screen events', events);
            //this.screenEvents = [];
            events.each(function (event) {
                this.screenEvents.push(this.addEvent(event));
            }.bind(this));
        }
    },

    cleanEvents: function (type) {
        if (type == 'app') {
            this.appEvents.each(function (event) {
                this.removeEvent(event);
            }.bind(this));
            this.appEvents.length = 0;
        } else {
            this.screenEvents.each(function (event) {
                this.removeEvent(event);
            }.bind(this));
            this.screenEvents.length = 0;
        }
    }


});

