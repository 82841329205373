appbuilder = window.appbuilder || {};
appbuilder.app = appbuilder.app || {};

(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();
})(window,document,'script','//www.google-analytics.com/analytics.js','ga');

appbuilder.app.Analytics = {
	
	events: true,
	pages: true,
	
	attach: function(phone) {
		appbuilder.app.debug('analytics', 'attach');
		phone.addEvent('app', function(id, app) {
			if(id != 1) {
				this.currentAppName = app.get('data-name') || 'App #' + id;
				this.currentAppId = id;

				this.pushVar(1, id);
				this.pushVar(2, 0);
				this.pushEvent('appbuilder', 'app', this.currentAppName, id);
				this.pushPageview('app/' + this.currentAppName + '-' + id);
			}
		}.bind(this));
		
		phone.addEvent('screen', function(id, screen) {
			if(id != 1) {
				var t = screen.getElement('.header .title');
				var screenName = t ? t.get('text') : 'Screen #' + id;

				this.pushVar(2, id);
				this.pushEvent('appbuilder', 'screen', this.currentAppName + ' - ' + screenName, id);
				this.pushPageview('app/' + this.currentAppName  + '-'+ this.currentAppId + '/' + screenName + '-' + id);
			}
		}.bind(this));
		
		this.load();
	},
	
	setAccount: function(a) {
		appbuilder.app.debug('analytics', 'setAccount', a);
		this.account = true;
		this.accountHandle = a;
		if(!appbuilder.app.hasGAPlugin) {
            ga('create', a, 'auto', {'name': 'custom'});
		}
	},
	
	pushEvent: function(cat, action, label, value) {
		appbuilder.app.debug('analytics', 'pushEvent', cat, action, label, value);
		if(this.events) {
			if(appbuilder.app.hasGAPlugin) {
				if(this.pluginReady) {
					window.plugins.gaPlugin.trackEvent(function(){}, function(){}, cat, action, label, value);
				}
			}
			else {
                if(this.account) {
					ga('custom.send', 'event',  {
                        'eventCategory': cat,
                        'eventAction': action,
                        'eventLabel': label,
                        'eventValue': value
                    });
				}
				ga('send', 'event',  {
                    'eventCategory': cat,
                    'eventAction': action,
                    'eventLabel': label,
                    'eventValue': value
                });
			}
		}
	},
	
	//mainonly is used by popup to push pageviews
	pushPageview: function(path, mainonly) {
		appbuilder.app.debug('analytics', 'pushPageview', path);
		if(this.pages) {
			if(appbuilder.app.hasGAPlugin) {
				if(this.pluginReady) {
					window.plugins.gaPlugin.trackPage(function(){}, function(){}, "phonegap.appshed.com" + path);
				}
			}
			else {
				if(this.account && !mainonly) {
                    ga('custom.send', 'pageview', path);
				}
				ga('send', 'pageview', path);
			}
		}
	},
	
	pushVar: function(index, value) {
		appbuilder.app.debug('analytics', 'pushVar', index, value);
		if(appbuilder.app.hasGAPlugin) {
			if(this.pluginReady) {
				window.plugins.gaPlugin.setVariable(function(){}, function(){}, index, value);
			}
		}
		else {
			if(this.account) {
				ga('custom.set', 'dimension'+index, value);
			}
			ga('set', 'dimension'+index, value);
		}
	},
	
	loaded: false,
	
	load: function() {
		if(!this.loaded) {
			if(appbuilder.app.hasGAPlugin) {
				appbuilder.app.debug('analytics', 'load', 'plugin');
				var loaded = function(){
					this.pluginReady = true;
					this.pushVar(3, 1);
				}.bind(this);
				if(this.account) {
					window.plugins.gaPlugin.init(loaded, function(){}, this.accountHandle, 10);
				}
				else {
					window.plugins.gaPlugin.init(loaded, function(){}, 'UA-19920027-1', 10);
				}
			}
			else {
				appbuilder.app.debug('analytics', 'load', 'ga.js');
                ga('create', 'UA-19920027-1', 'auto');
				this.pushVar(3, appbuilder.app.isPhoneGap ? 1 : 0);
				//When the phone is embeded in the editor ga.js could get loaded twice
				function injectScript() {
					(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                    })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
					window.removeEventListener && window.removeEventListener('online',  injectScript);
				}
				if(appbuilder.app.online()) {
					injectScript();
				}
				else {
					 window.addEventListener && window.addEventListener('online',  injectScript);
				}
			}
			this.loaded = true;
		}
	}
};
