if (window.applicationCache) {
    window.addEvent('domready', function() {
        var updateEl = new Element('div', {'id': 'phone-cache-progress'}), startUpdate, diff;
            
		window.applicationCache.addEventListener('updateready', function(e) {
			appbuilder.app.debug('cache', 'update', e);
			if (window.applicationCache.status == window.applicationCache.UPDATEREADY) {
				// Browser downloaded a new app cache.
				// Swap it in and reload the page to get the new hotness.
				try {
					window.applicationCache.swapCache();
				}
				catch(err) {
					appbuilder.app.debug('cache', 'swap', err);
				}
                setTimeout(function() {
                    appbuilder.app.confirm('New version of the app is available. Update the app?', function(c) {
                        if(c) {
                            window.location.reload();
                        }
                    });
                }, (diff = new Date() - (startUpdate || new Date())) > 15000 ? 0 : 15000 - diff);
			} else {
			// Manifest didn't changed. Nothing new to server.
			}
		}, false);
		window.applicationCache.addEventListener('downloading', function() {
            updateEl.inject(document.body);
			appbuilder.app.debug('cache', 'downloading');
			appbuilder.app.alert('Downloading updates to app');
            startUpdate = new Date();
		}, false);
		window.applicationCache.addEventListener('noupdate', function() {
			appbuilder.app.debug('cache', 'noupdate');
		}, false);
		window.applicationCache.addEventListener('progress', function(e) {
            var progress = (e.loaded / e.total) * 100;
            updateEl.setStyles({width: progress + '%'});
			appbuilder.app.debug('cache', 'progress', progress, e);
		}, false);
		window.applicationCache.addEventListener('cached', function() {
			updateEl.remove();
            appbuilder.app.debug('cache', 'cached');
			appbuilder.app.alert('App is now available offline');
		}, false);
		window.applicationCache.addEventListener('error', function(e) {
            updateEl.remove();
			appbuilder.app.debug('cache', 'error', e);
		}, false);
	});
}