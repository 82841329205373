appbuilder = window.appbuilder || {};
appbuilder.app = appbuilder.app || {};
appbuilder.app.Phone = new Class({
	Implements: [Options, Events],

	options: {
		request: {
			url: 'index.php',
			data: {
				option: 'com_appbuilder',
				controller: 'appserver'
			},
			method: 'get',
			concurrent : 10,
			timeout: 15000,
			timeoutUpdate: 5000
		},
		fullscreen: false,
		background: true,
		statusbar: true,
		storage: false,
		files: false,
		update: false,
		updateSingle: false,
		browserHistory: false,
		splash: false,
		style: 'auto',
		homeButton: false,
		horizontal: false,
		fixed: false,
        masking: (function() {
            var prefixes = ['maskRepeat', 'webkitMaskRepeat', 'mozMaskRepeat', 'oMaskRepeat', 'msMaskRepeat'],
                d = document.createElement('div');
            for (var i = 0; i < prefixes.length; i++) {
                if (d.style[prefixes[i]] !== undefined) {
                    return prefixes[i];
                }
            }
            return false;
        })(),
        currentIOS7: true
	},

	initialize: function(options) {
		appbuilder.app.debug('phone', 'init');
		this.setOptions(options);
		
		this.temporaryStorage = appbuilder.app.Storage;
		this.temporaryStorage.phone = this;
		if(this.options.storage) {
			//if(appbuilder.app.PhoneGapStorage) {
			//	this.storage = appbuilder.app.PhoneGapStorage;
			//}
			if(appbuilder.app.LocalStorage) {
				this.storage = appbuilder.app.LocalStorage;
				this.storage.phone = this;
			}
		}
		
		this.history = new appbuilder.app.History(this);
		this.loader = new appbuilder.app.Loader(this);
		this.navigator = new appbuilder.app.Navigator(this);
		
		appbuilder.app.Analytics.attach(this);
		
		if(!appbuilder.app.current) {
			appbuilder.app.current = this;
		}

        this.addEvent('app', function() {
            if (this.options.currentMasking && document.id(this).hasClass('ios7style')) {
                this.navigator.applyTabMask();
            }
        }.bind(this));
	},

	toElement: function() {
		if(!this.element) {
			this.element = appbuilder.app.makeElement('phone');

            // usb cable
            var cable = new Element('div', {html: '<i></i>', class: 'cable'});
            cable.inject(this.element);
			
			//Platform specific classes
			if(appbuilder.app.isPhoneGap) {
				this.element.addClass('phonegap');
			}
			if(Browser.Platform.ios) {
				this.element.addClass('ios');
                if (Browser.Platform.version >= 7) {
                    this.element.addClass('ios7');
                }
                //Fix for ios7 ipad landscape height bug
                if (Browser.Platform.ipadIos7 && this.options.fullscreen && !window.navigator.standalone) {
                    document.id(document.body).addClass('ios7-ipad-fix');
                }
			}
				
			if(Browser.Platform.android) {
				this.element.addClass('android-os');
			}

			if(Browser.Platform.blackberry) {
				this.element.addClass('blackberry-os');
				if(Browser.Platform.playBook) {
					this.element.addClass('blackberry-os-playbook');
				}
				else if(Browser.Platform.smartphone) {
					this.element.addClass('blackberry-os-smartphone');
				}
				else if(Browser.Platform.BB10) {
					this.element.addClass('blackberry-os-bb10');
				}
				//Always used fixed position on bb<playbook
				if(Browser.version < 7.2) {
					this.options.fixed = true;
				}
			}
			
			if(this.options.fixed) {
				this.element.addClass('fixed');
				//Fixes the min-height of .items when using fixed positioning
				appbuilder.app.startHeightTracker();
			}
			
			if(this.options.fullscreen) {
				this.element.addClass('fullscreen');
				
				if(appbuilder.app.isMobile && !this.options.fixed) {
					//Fixes the height of the app on iphone/pod in Safari
					if(!appbuilder.app.isPhoneGap && !window.navigator.standalone && (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i))) {
						var resize = function() {
							if(Browser.version >= 7) {
								if(window.innerHeight > window.innerWidth){
									this.element.setStyle('height', window.getSize().y);
									this.element.removeClass('auto-height');
								}
                                                                else {
									this.element.removeProperty('height');
									this.element.addClass('auto-height');
								}
							}
                                                        else {
                                                            this.element.setStyle('height', window.getSize().y + 60);
							}
						}.bind(this);
						this.addEvent('resize', resize);
						resize();
					}

					//Stops the app from scrolling with touches
					var resetPos = (function() {
                                            //Allow scrolling ios7 landscape
                                            if(!((Browser.Platform.ipodIos7 || Browser.Platform.iphoneIos7) && window.innerHeight < window.innerWidth && !window.navigator.standalone)) {
                                                window.scrollTo(0, 1);
                                            }
                                        });
                                        resetPos.delay(100);
                                        this.element.addEvent('touchstart', resetPos);

                                        this.element.addEvent('touchmove', function (e) {
                                            //Allow scrolling ios7 landscape
                                            if(!((Browser.Platform.ipodIos7 || Browser.Platform.iphoneIos7) && window.innerHeight < window.innerWidth && !window.navigator.standalone)) {
                                                e.preventDefault();
                                            }
                                        });
				}
				//relay to internal resize event
				window.addEvent('resize', function(e) {
					this.fireEvent('resize', [e]);
				}.bind(this));
			}
			else {
				if(this.options.background == 'half') {
					this.element.addClass('background half');
				}
				else if(this.options.background === true || this.options.background == 'full') {
					this.element.addClass('background full');
				}
			}
			
			this.setStyle(this.options.style, true);
			this.setHorizontal(this.options.horizontal, true);
			this.setRealSize(this.options.realSize, true);
			
			var inner = appbuilder.app.makeElement('phone-inner').inject(this.element);
			
			this.status = appbuilder.app.makeElement('status-bar', {
				'html':new Date().format('%H:%M')
			}).inject(inner);
			
			(function() {
				this.status.set('html', new Date().format('%H:%M'));
			}).periodical(60000, this);
			
			if(this.options.statusbar) {
				this.element.addClass('status');
			}
			
			document.id(this.loader).inject(inner);
			
			document.id(this.navigator).inject(inner);
			
			if(this.options.homeButton) {
				appbuilder.app.makeElement('home-button', {
					'data-linktype': 'app',
					'data-href': 1,
					'data-no-glow': 'no-glow'
				}).inject(this.element);
			}
			
			//Start the app
			if(!this.history.checkHistory() && this.options.app != undefined) {
				this.navigator.linktypes.app.apply(this.navigator, [this.options.app, true, this.options.screen ? function() {
					this.navigator.linktypes.screen.apply(this.navigator, [this.options.screen, false]);
				}.bind(this) : null]);
			}
		}
		return this.element;
	},
	
	setStyle: function(style, noresize) {
		appbuilder.app.debug('phone', 'style', style);
		var styles = ['iphone', 'iphone5', 'iphone5s', 'ipad', 'ipadair', 'android', 'androidsm', 'blackberry', 'blackberry10', 'playbook', 'nexus7', 'auto', 'ios7style'],
			m, v;
		if(styles.indexOf(style) != -1) {
			this.options.style = style;
			if(style == 'auto') {
				if(Browser.Platform.android) {
					if(Browser.version < 4) {
						style = 'androidsm';
					}
					else {
						style = 'android';
					}
				}
				else if(Browser.Platform.blackberry) {
					if(Browser.Platform.playBook || Browser.Platform.BB10 || Browser.version >= 10) {
						style = 'blackberry10';
					}
					else {
						style = 'blackberry';
					}
				}
				else {
					//Default style on unknown platform
                    if(Browser.version < 7) {
                        style = 'iphone';
                    }
                    else {
                        style = 'iphone5s'
                    }
				}
			}
            if (!this.options.currentIOS7) {
                if (style == 'iphone5s') {
                    style = 'iphone5';
                }
                if (style == 'ipadair') {
                    style = 'ipad';
                }
                if (style == 'ios7style') {
                    style = 'iphone';
                }
            }
			if(this.element) {
				styles.each(function(style) {
					this.element.removeClass(style);
				}, this);
				this.element.addClass(style);
				if(style == 'nexus7') {
					//nexus7 style is based on android
					this.element.addClass('android');
				}
				else if(style == 'playbook') {
					//playbook style is based on bb10
					this.element.addClass('blackberry10');
				}
                else if(style == 'iphone5s') {
                    //5s style is based on 5
                    this.element.addClass('ios7style iphone5');
                }
                else if(style == 'ipadair') {
                    //ipadair style is based on ipad
                    this.element.addClass('ios7style ipad');
                }
				if(this.options.background) {
					if(this.options.background === 'half') {
						this.element.addClass('half');
					}
					this.element.addClass('background');
				}

                if (this.options.currentMasking && (style == 'ios7style' || style == 'iphone5s' || style == 'ipadair')) {
                    this.navigator.applyTabMask();
                }
                else {
                    this.navigator.removeTabMask();
                }
				!noresize && this.fireEvent('resize');
			}
		}
	},
	
	setHorizontal: function(hor, noresize) {
		this.options.horizontal = hor;
		if(this.element) {
			if(hor) {
				this.element.addClass('horizontal');
			}
			else {
				this.element.removeClass('horizontal');
			}
			!noresize && this.fireEvent('resize');
		}
	},
	
	setRealSize: function(realSize, noresize) {
		this.options.realSize = realSize;
		if(this.element) {
			if(realSize) {
				this.element.addClass('realsize');
			}
			else {
				this.element.removeClass('realsize');
			}
			!noresize && this.fireEvent('resize');
		}
	},
	
	updateApp: function(id, cb, appUrl) {
		if(this.options.update && this.storage && appbuilder.app.online()) {
			appbuilder.app.debug('phone', 'update', 'starting');
			this.loader.showLoading();
			this.temporaryStorage.clear();
			this.getLastUpdate(id, function(update) {
				var loaded = function(data) {
					if(data) {
						appbuilder.app.debug('phone', 'update', 'updating');
						Object.each(data, function(data, type) {
							if(type == 'time') {
								this.setLastUpdate(id, data);
							}
							else {
								Object.each(data, function(obj, fetchId) {
									this.loaded(obj);
								}, this);
							}
						}, this);
						this.storage.flush();
						this.loader.hideLoading();
						cb(true);
					}
					else {
						appbuilder.app.debug('phone', 'update', 'nothing');
						this.loader.hideLoading();
						cb(false);
					}
				}.bind(this),
				error = function(msg) {
					appbuilder.app.debug('phone', 'update', 'error', msg);
					this.loader.hideLoading();
					cb(false);
				}.bind(this);
				this.loader.request('update', id, update, loaded, error, null, this.options.request.timeoutUpdate);
			}.bind(this));
		}
		else {
			appbuilder.app.debug('phone', 'update', 'disabled');
			cb(false);
		}
	},
	
	getLastUpdate: function(id, cb) {
		if(this.storage) {
			this.storage.fetch('update', id, function(update) {
				if(!update) {
					this.storage.store('update', id, {
						update: this.options.published
					});
					cb(this.options.published);
				}
				else {
					cb(update.update);
				}
			}.bind(this));
		}
		else {
			cb(this.options.published);
		}
	},
	
	setLastUpdate: function(id, time) {
		if(this.storage) {
			this.storage.store('update', id, {
				update: time
			});
		}
	},
	
	getUniqueId: function() {
		if(this.uniqueId) {
			return this.uniqueId;
		}
		if(appbuilder.app.isPhoneGap) {
			return device.uuid;
		}
		if(this.storage) {
			this.uniqueId = this.storage.fetch('device', 'unique');
			if(this.uniqueId) {
				return this.uniqueId;
			}
		}
		var d = new Date();
		this.uniqueId = (d++).toString(36) + (new Date().getMilliseconds().toString(36));
		if(this.storage) {
			this.storage.store('device', 'unique', this.uniqueId);
		}
		return this.uniqueId;
	},
	
	fetch: function(type, id, cb, fetchId, fetchUrl, direct) {
		cb = this.loadingcb(cb);
		
		var obj = this.temporaryStorage.fetch(type, id);
		if(obj) {
			cb(obj);
			return;
		}
		if(this.storage) {
			obj = this.storage.fetch(type, id);
			if(obj) {
				this.updateSingle(type, id, obj, cb, fetchId);
				return;
			}
		}
		if(this.options.files && !fetchUrl) {
			appbuilder.app.FileLoader.fetch(type, id, function(obj) {
				if(obj) {
					this.updateSingle(type, id, obj, cb, fetchId);
				}
				else if(appbuilder.app.online()) {
					this.loader.fetch(type, id, cb, null, fetchId);
				}
				else {
					cb(false);
				}
			}.bind(this), fetchId, this);
		}
		else if(fetchUrl && appbuilder.app.online()) {
			this.fetchLive(direct ? 'direct' : 'remote', fetchUrl, function(obj, fetchedObj) {
				if(fetchedObj && fetchedObj[type]) {
					cb(fetchedObj[type][id]);
				}
				else {
					cb(false);
				}
			}.bind(this));
		}
		else if(appbuilder.app.online()) {
			this.loader.fetch(type, id, cb, null, fetchId);
		}
		else {
			cb(false);
		}
	},
	
	fetchLive: function(type, id, cb, postVars, alwaysLive) {
		if(!alwaysLive) {
			var obj = this.temporaryStorage.fetch(type == 'direct' ? 'remote' : type, id);
			if(obj) {
				cb(obj);
				return;
			}
			if(this.storage) {
				obj = this.storage.fetch(type == 'direct' ? 'remote' : type, id);
				if(obj) {
					cb(obj);
					return;
				}
			}
		}
		if(appbuilder.app.online()) {
			this.loader.fetch(type, id, function(obj, fetchedObj) {
				if(obj) {
					cb(obj, fetchedObj);
				}
				else if(!alwaysLive && this.options.files) {
					appbuilder.app.FileLoader.fetch(type == 'direct' ? 'remote' : type, id, cb, null, this);
				}
				else {
					cb(false);
				}
			}.bind(this), false, false, postVars);
			return;
		}
		else if(!alwaysLive && this.options.files) {
			appbuilder.app.FileLoader.fetch(type == 'direct' ? 'remote' : type, id, cb, null, this);
		}
		else {
			cb(false);
		}
	},
	
	loadingcb: function(cb) {
		this.loader.showLoading();
		return function() {
			this.loader.hideLoading();
			cb.apply(this, arguments);
		}.bind(this);
	},
	
	updateSingle: function(type, id, obj, cb, fetchId) {
		if(appbuilder.app.online() && obj.updated && this.options.updateSingle) {
			appbuilder.app.debug('phone', 'updateSingle', 'starting');
			this.loader.fetch(type, id, function(updatedObj) {
				if(!updatedObj) {
					appbuilder.app.debug('phone', 'updateSingle', 'nothing');
					this.temporaryStorage.store(type, id, obj);
					cb(obj);
				}
				else {
					appbuilder.app.debug('phone', 'updateSingle', 'updated');
					cb(updatedObj);
				}
			}.bind(this), obj.updated, fetchId);
		}
		else {
			appbuilder.app.debug('phone', 'updateSingle', 'disabled');
			this.temporaryStorage.store(type, id, obj);
			cb(obj);
		}
	},
	
	/**
	 * @param {refreshes} bool If true then refresh the current app/screen
	 * @param {bool} files If true then never store in long term storage
	 */
	loaded: function(obj, refreshes, files) {
		this.temporaryStorage.storeObj(obj);
		if(this.storage && !files) {
			this.storage.storeObj(obj);
		}
		if(refreshes) {
			this.flushStorage();
			if(obj.app && obj.app[this.history.currentApp]) {
				this.history.refresh();
			}
			else if(obj.screen && obj.screen[this.history.currentScreen()]) {
				this.history.refreshScreen();
			}
		}
	},
	
	flushStorage: function() {
		appbuilder.app.debug('phone', 'flush');
		this.temporaryStorage.flush();
		if(this.storage) {
			this.storage.flush();
		}
	},
	
	clearStorage: function() {
		appbuilder.app.debug('phone', 'clear');
		this.temporaryStorage.clear();
		if(this.storage) {
			this.storage.clear();
		}
	}
}, 'Phone');
